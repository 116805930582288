import React, { useContext } from "react";
import c from "classnames";
import {
  useParams,
  Redirect,
  Switch,
  Route,
  Link,
  useLocation,
} from "react-router-dom";

import { AuthContext } from "src/services/authService";
import { QuizProvider, QuizContext } from "src/services/dataService";
import { HOME_URL, HOST_QUIZ_URL } from "src/constants";
import { QuizMasterProtectedRoute } from "src/routes";
import { PageLayout } from "src/components/PageLayout";

import { QuizDetails } from "./QuizDetails";
import { RoundDetails } from "./RoundDetails";

function HostQuiz() {
  const { user } = useContext(AuthContext);
  const { quiz, players, approvedPlayers } = useContext(QuizContext);
  const { pathname } = useLocation();

  if (quiz?.createdBy !== user?.uid) {
    return <Redirect to={HOME_URL} />;
  }

  const quizUrl = `${HOST_QUIZ_URL}/${quiz!.id}`;

  return (
    <PageLayout
      heading={quiz?.title}
      action={
        pathname === quizUrl ? undefined : (
          <Link to={quizUrl} className={c("qb-a", "w-full")}>
            Back
          </Link>
        )
      }
      className="w-full"
    >
      <Switch>
        <QuizMasterProtectedRoute exact={true} path={quizUrl}>
          <QuizDetails
            quiz={quiz!}
            quizUrl={quizUrl}
            players={players}
            approvedPlayers={approvedPlayers}
          />
        </QuizMasterProtectedRoute>

        {quiz?.rounds.map((round, index) => (
          <QuizMasterProtectedRoute
            key={`quiz-round-route-${index}`}
            exact={true}
            path={`${quizUrl}/round/${round.roundNumber}`}
          >
            <RoundDetails
              quizUrl={quizUrl}
              quizId={quiz.id}
              round={round}
              approvedPlayers={approvedPlayers}
            />
          </QuizMasterProtectedRoute>
        ))}

        <Route>
          <Redirect to={quizUrl} />
        </Route>
      </Switch>
    </PageLayout>
  );
}

export function HostQuizPage() {
  const { quizId = "" } = useParams<{ quizId: string }>();

  return (
    <QuizProvider quizId={quizId}>
      <HostQuiz />
    </QuizProvider>
  );
}
