export const HOME_URL = "/";
export const LOGIN_URL = "/login";
export const CREATE_ACCOUNT_URL = "/create-account";
export const CREATE_QUIZ_URL = "/create-quiz";
export const PLAYER_QUIZ_URL = "/quiz";
export const HOST_QUIZ_URL = "/host-quiz";
export const EDIT_QUIZ_URL = "/edit-quiz";
export const MY_QUIZZES_URL = "/my-quizzes";

export const ERROR_MESSAGE = {
  DEFAULT: "Something went wrong, try again",
};
