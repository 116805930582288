import React, { useState, useEffect } from "react";
import c from "classnames";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

import { ERROR_MESSAGE } from "src/constants";
import { Button } from "src/components/Button";
import { Notification, NotificationState } from "src/components/Notification";
import { deleteQuiz, Quiz } from "src/services/dataService";
import { ModalConfirmation } from "src/components/Modal";

interface DeleteQuizButtonProps {
  quiz: Quiz;
  className?: string;
}

export function DeleteQuizButton({ quiz, className }: DeleteQuizButtonProps) {
  const [error, setError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    return function () {
      setIsModalOpen(false);
    };
  }, [quiz]);

  return (
    <div className={c("w-full", className)}>
      <Button
        className={c("qb-btn-danger")}
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        Delete Quiz
        <DeleteForeverIcon fontSize="inherit" className={c("ml-4")} />
      </Button>

      <ModalConfirmation
        aria-labelledby="delete-quiz-modal-title"
        aria-describedby="delete-quiz-modal-description"
        open={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
          setError(false);
        }}
        onConfirm={() => {
          setError(false);
          deleteQuiz(quiz.id)
            .then(() => {})
            .catch(() => {
              setError(true);
            });
        }}
      >
        <div className={c("w-full", "flex", "flex-col", "items-center")}>
          <h2 id="delete-quiz-modal-title" className={c("text-3xl")}>
            Delete Quiz
          </h2>
          <p
            id="delete-quiz-modal-description"
            className={c("text-xl", "mt-8")}
          >
            Are you sure you want to delete quiz {quiz.title}?
          </p>
          {error ? (
            <Notification className="mt-8" state={NotificationState.ERROR}>
              {ERROR_MESSAGE.DEFAULT}
            </Notification>
          ) : null}
        </div>
      </ModalConfirmation>
    </div>
  );
}
