import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import { HOME_URL, ERROR_MESSAGE } from "src/constants";
import { AuthContext, signOut } from "src/services/authService";
import { Notification, NotificationState } from "src/components/Notification";

export function SignOut() {
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const [isError, setIsError] = useState(false);

  return user ? (
    <>
      <p className="text-2xl mb-8">
        Logged in as {user.displayName}
        <br />
        Not you?
      </p>
      {isError ? (
        <Notification className="mb-8" state={NotificationState.ERROR}>
          {ERROR_MESSAGE.DEFAULT}
        </Notification>
      ) : null}
      <button
        className="qb-btn"
        onClick={async () => {
          setIsError(false);
          try {
            await signOut();
            history.push(HOME_URL);
          } catch {
            setIsError(true);
          }
        }}
      >
        Sign Out
      </button>
    </>
  ) : null;
}
