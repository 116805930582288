import React from "react";
import c from "classnames";

import { Quiz, QUIZ_STATUS } from "src/services/dataService";
import {
  StartTimeDisplay,
  ScheduledStartTimeDisplay,
} from "./StartTimeDisplay";

interface QuizStatusProps {
  quiz: Quiz;
}

export function QuizStatus({ quiz }: QuizStatusProps) {
  const { scheduledStartUnix, status } = quiz;

  if (status === QUIZ_STATUS.STARTED) {
    return (
      <>
        <p className={c("text-2xl", "text-success")}>Started</p>
        <StartTimeDisplay scheduledStartUnix={scheduledStartUnix} />
      </>
    );
  }

  if (status === QUIZ_STATUS.COMPLETED) {
    return (
      <>
        <p className={c("text-2xl", "text-primary")}>Completed</p>
        <StartTimeDisplay scheduledStartUnix={scheduledStartUnix} />
      </>
    );
  }

  return <ScheduledStartTimeDisplay scheduledStartUnix={scheduledStartUnix} />;
}
