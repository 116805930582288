import React from "react";
import c from "classnames";

import { Player, Question } from "src/services/dataService";
import { NumberInput } from "src/components/NumberInput";

import { getScoreKey } from "./utils";

interface RoundQuestionProps {
  roundNumber: number;
  question: Question;
  showAnswer: boolean;
  players: Player[];
}

export function RoundQuestion({
  roundNumber,
  question: { questionNumber, imageUrl, text, answer },
  showAnswer,
  players,
}: RoundQuestionProps) {
  return (
    <li
      className={c(
        "mb-10",
        "flex",
        "flex-col",
        "items-center",
        "justify-between",
        "w-full",
        "mx-4",
        "sm:w-500"
      )}
    >
      {imageUrl ? (
        <div className={c("w-full", "flex-1", "flex", "items-center")}>
          <img
            src={imageUrl}
            alt={`Question number ${questionNumber}`}
            className={c("w-full", "h-auto", "mb-4")}
          />
        </div>
      ) : null}
      <div className={c("text-xl", "mb-8")}>
        <div>
          Question {questionNumber}
          <span className={c("text-2xl", "font-medium", "whitespace-pre-line")}>
            {text ? `: ${text}` : ""}
          </span>
        </div>
        {showAnswer ? (
          <div
            className={c(
              "text-xl",
              "mb-8",
              "text-primary",
              "whitespace-pre-line"
            )}
          >
            <div>
              Correct answer:{" "}
              <span
                className={c("text-2xl", "font-medium", "whitespace-pre-line")}
              >
                {answer}
              </span>
            </div>
            <table
              className={c(
                "table-auto",
                "w-full",
                "max-w-4xl",
                "text-center",
                "mt-4"
              )}
            >
              <thead className={c("text-lg")}>
                <tr>
                  <th>Team</th>
                  <th>Answer</th>
                  <th>Score</th>
                </tr>
              </thead>
              <tbody className={c("text-base")}>
                {players
                  .map((player) => ({
                    ...player,
                    playerRound: player.rounds.find(
                      (round) => round.roundNumber === roundNumber
                    ),
                  }))
                  .map((player) => (
                    <tr
                      id={`host-round-${player.teamName}-answer-${questionNumber}`}
                      key={`host-round-${player.teamName}-answer-${questionNumber}`}
                    >
                      <td>{player.teamName}</td>
                      {player.playerRound?.isSubmitted ? (
                        <>
                          <td className="whitespace-pre-line">
                            {player.playerRound?.answers?.find(
                              (a) => a.questionNumber === questionNumber
                            )?.answer || " - "}
                          </td>
                          <td>
                            <NumberInput
                              name={getScoreKey({
                                playerId: player.id,
                                roundNumber,
                                questionNumber,
                              })}
                              aria-labelledby={`host-round-${player.teamName}-answer-${questionNumber}`}
                            />
                          </td>
                        </>
                      ) : (
                        <td colSpan={2}>- Not submitted -</td>
                      )}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        ) : null}
      </div>
    </li>
  );
}
