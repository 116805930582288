import React, { useState } from "react";
import c from "classnames";
import { Formik } from "formik";

import { Modal, ModalFormWrapper, ModalProps } from "src/components/Modal";
import { emailValidation } from "src/validationSchemas";
import { Notification, NotificationState } from "src/components/Notification";
import { ERROR_MESSAGE } from "src/constants";
import { Input } from "src/components/Input";
import { sendPasswordReset } from "src/services/authService";

interface PasswordResetModalProps extends Omit<ModalProps, "children"> {
  email: string;
  onCancel: () => void;
  onSuccess: (email: string) => void;
}

export function PasswordResetModal({
  email,
  onCancel,
  onSuccess,
  ...modalProps
}: PasswordResetModalProps) {
  const [error, setError] = useState("");

  const labelledBy = "password-reset-form-title";
  const describedBy = "password-reset-form-description";

  return (
    <Modal
      aria-labelledby={labelledBy}
      aria-describedby={describedBy}
      {...modalProps}
    >
      <div className={c("w-full")}>
        <h2
          id={labelledBy}
          className={c(
            "text-3xl",
            "w-full",
            "px-4",
            "mt-4",
            "mb-2",
            "text-center"
          )}
        >
          Password Reset
        </h2>
        <p
          id={describedBy}
          className={c("text-2xl", "w-full", "px-4", "mb-4", "text-center")}
        >
          Submit your email to receive a password reset link.
        </p>

        <Formik
          initialValues={{ email }}
          validationSchema={emailValidation}
          onSubmit={async ({ email }) => {
            setError("");
            try {
              await sendPasswordReset(email);
              onSuccess(email);
            } catch (error) {
              if (error?.message) {
                setError(error.message);
              } else {
                setError(ERROR_MESSAGE.DEFAULT);
              }
            }
          }}
        >
          {() => (
            <ModalFormWrapper
              onCancel={onCancel}
              className={c("flex", "justify-center")}
            >
              <div className={c("w-full", "max-w-lg")}>
                <Input
                  name="email"
                  label="Email"
                  id="password-reset-email"
                  autoComplete="on"
                />
                {error ? (
                  <div className={c("flex", "justify-center", "text-center")}>
                    <Notification
                      className="mt-8"
                      state={NotificationState.ERROR}
                    >
                      {error}
                    </Notification>
                  </div>
                ) : null}
              </div>
            </ModalFormWrapper>
          )}
        </Formik>
      </div>
    </Modal>
  );
}
