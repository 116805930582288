import React, { useContext } from "react";
import c from "classnames";

import { QuizContext, Player, Quiz } from "src/services/dataService";

interface PlayerWithScores {
  teamName: string;
  totalScore: number;
}

interface PlayerWithFullScores extends PlayerWithScores {
  [key: string]: number | string;
}

function FullResults({ players, quiz }: { players: Player[]; quiz: Quiz }) {
  const playersWithScores: PlayerWithFullScores[] = players.map(
    ({ teamName, rounds }) => {
      const p: PlayerWithFullScores = {
        teamName,
        totalScore: rounds
          .filter((r) => r.isMarked)
          .reduce((accRounds, curRound) => {
            return (
              accRounds +
              curRound.answers.reduce((acc, cur) => acc + (cur.score || 0), 0)
            );
          }, 0),
      };
      quiz.rounds.forEach(({ roundNumber }) => {
        p[`score${roundNumber}`] =
          rounds
            .filter((r) => r.isMarked)
            .find((r) => r.roundNumber === roundNumber)
            ?.answers.reduce((acc, cur) => acc + (cur.score || 0), 0) ?? "";
      });
      return p;
    }
  );
  playersWithScores.sort((a, b) => b.totalScore - a.totalScore);

  return (
    <div className={c("w-full", "flex", "flex-col", "items-center")}>
      <table className={c("table-auto", "w-full", "max-w-5xl", "text-center")}>
        <thead className={c("text-lg")}>
          <tr>
            <th>Team Name</th>

            {quiz.rounds.map(({ title }, index) => (
              <th key={`host-quiz-leader-board-round-title-${index}`}>
                {title}
              </th>
            ))}

            <th>Total Score</th>
          </tr>
        </thead>
        <tbody className={c("text-base")}>
          {playersWithScores.map((player, index) => (
            <tr key={`host-quiz-leader-board-teamname-${index}`}>
              <td>{player.teamName}</td>
              {quiz.rounds.map(({ roundNumber }, index) => (
                <td key={`host-quiz-leader-board-round-score-${index}`}>
                  {player[`score${roundNumber}`]}
                </td>
              ))}
              <td>{player.totalScore}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function TotalScoreResultsOnly({ players }: { players: Player[] }) {
  const playersWithScores: PlayerWithScores[] = players.map(
    ({ teamName, rounds }) => ({
      teamName,
      totalScore: rounds
        .filter((r) => r.isMarked)
        .reduce((accRounds, curRound) => {
          return (
            accRounds +
            curRound.answers.reduce((acc, cur) => acc + (cur.score || 0), 0)
          );
        }, 0),
    })
  );
  playersWithScores.sort((a, b) => b.totalScore - a.totalScore);

  return (
    <div className={c("w-full", "flex", "flex-col", "items-center")}>
      <table className={c("table-auto", "w-full", "max-w-5xl", "text-center")}>
        <thead className={c("text-lg")}>
          <tr>
            <th>Team Name</th>
            <th>Total Score</th>
          </tr>
        </thead>
        <tbody className={c("text-base")}>
          {playersWithScores.map(({ teamName, totalScore }, index) => (
            <tr key={`host-quiz-leader-board-teamname-${index}`}>
              <td>{teamName}</td>
              <td>{totalScore}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export function ResultsTable() {
  const { quiz, approvedPlayers } = useContext(QuizContext);

  if (quiz) {
    return (
      <>
        <div className={c("hidden", "sm:block")}>
          <FullResults quiz={quiz} players={approvedPlayers} />
        </div>
        <div className={c("block", "sm:hidden")}>
          <TotalScoreResultsOnly players={approvedPlayers} />
        </div>
      </>
    );
  }

  return null;
}
