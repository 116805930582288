import React from "react";
import { useField } from "formik";
import { DateTimePicker } from "@material-ui/pickers";
import { TextFieldProps } from "@material-ui/core";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

import { Input, InputProps } from "src/components/Input";

dayjs.extend(localizedFormat);

export function DateInput({ name, ...props }: InputProps) {
  const [field, , helpers] = useField(name);

  return (
    <DateTimePicker
      value={field.value}
      onChange={(date) => {
        helpers.setValue(date);
      }}
      ampm={false}
      autoOk={true}
      disablePast={true}
      format="llll"
      TextFieldComponent={({
        onClick,
        onBlur,
        onFocus,
        onChange,
        value,
      }: TextFieldProps) => (
        <Input
          name={name}
          value={value}
          onClick={onClick}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={(event) => onChange && onChange(event)}
          {...props}
        />
      )}
    />
  );
}
