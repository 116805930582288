import React, { PropsWithChildren } from "react";
import c from "classnames";

export enum NotificationState {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

interface NotificationProps {
  state: NotificationState;
  className?: string;
}

export function Notification({
  state,
  children,
  className,
}: PropsWithChildren<NotificationProps>) {
  return (
    <div
      className={c(
        "w-full",
        "max-w-xl",
        "text-xl",
        "text-center",
        "pt-4",
        "pb-8",
        "px-8",
        "border",
        "border-solid",
        "border-white",
        "rounded",
        "text-white",
        className,
        {
          "bg-success": state === NotificationState.SUCCESS,
          "bg-error": state === NotificationState.ERROR,
        }
      )}
    >
      <div>
        {state === NotificationState.ERROR ? <span>X</span> : <span>☑</span>}
      </div>
      {children}
    </div>
  );
}
