import React, { useState } from "react";
import c from "classnames";
import { Formik, FieldArray, Field, ErrorMessage } from "formik";

import {
  Quiz,
  PLAYER_ADMISSION_STATUS,
  updateQuizPlayerAdmissionStatuses,
  Player,
} from "src/services/dataService";
import { Modal, ModalFormWrapper, ModalProps } from "src/components/Modal";
import { UserDisplayName } from "src/components/UserDisplayName";
import { playersApprovalValidation } from "src/validationSchemas";
import { Notification, NotificationState } from "src/components/Notification";
import { ERROR_MESSAGE } from "src/constants";

interface PlayerApprovalModalProps extends Omit<ModalProps, "children"> {
  quiz: Quiz;
  players: Player[];
  onCancel: () => void;
  onUpdated: () => void;
}

export function PlayerApprovalModal({
  quiz,
  players,
  onCancel,
  onUpdated,
  ...modalProps
}: PlayerApprovalModalProps) {
  const [error, setError] = useState("");
  const initialPlayers = [...players];
  initialPlayers.sort((a, b) => a.teamName.localeCompare(b.teamName));

  const labelledBy = "player-approval-form-title";
  const describedBy = "player-approval-form-description";

  return (
    <Modal
      aria-labelledby={labelledBy}
      aria-describedby={describedBy}
      {...modalProps}
    >
      <div className={c("w-full")}>
        <h2
          id={labelledBy}
          className={c(
            "text-3xl",
            "w-full",
            "px-4",
            "mt-4",
            "mb-2",
            "text-center"
          )}
        >
          {quiz.title}
        </h2>
        <p
          id={describedBy}
          className={c("text-2xl", "w-full", "px-4", "mb-4", "text-center")}
        >
          Approve or reject players' requests to join
        </p>

        <Formik
          initialValues={{ players: initialPlayers }}
          validationSchema={playersApprovalValidation}
          onSubmit={async (values, helpers) => {
            setError("");
            try {
              await updateQuizPlayerAdmissionStatuses(
                quiz.id,
                initialPlayers,
                values.players
              );
              onUpdated();
            } catch (error) {
              setError(ERROR_MESSAGE.DEFAULT);
              helpers.setSubmitting(false);
            }
          }}
        >
          {({ values }) => (
            <ModalFormWrapper onCancel={onCancel}>
              <FieldArray name="players">
                {() =>
                  values.players.map((player, index) => (
                    <fieldset
                      key={`player-approval-fieldset-${index}`}
                      className={c("text-center")}
                    >
                      <legend>
                        <span className={c("text-2xl", "text-primary")}>
                          Team: {player.teamName}
                        </span>
                        <br />
                        <span className={c("text-lg")}>
                          (User: <UserDisplayName uid={player.id!} />)
                        </span>
                      </legend>
                      <div className={c("flex", "justify-center", "mt-2")}>
                        <label
                          className={c(
                            "flex",
                            "flex-col",
                            "items-center",
                            "w-24",
                            "mr-6",
                            "text-xl",
                            "border",
                            "border-transparent",
                            "border-solid",
                            "rounded",
                            "px-4",
                            "pb-6",
                            "pt-2",
                            {
                              "text-success border-success":
                                player.admissionStatus ===
                                PLAYER_ADMISSION_STATUS.APPROVED,
                            }
                          )}
                        >
                          Approve
                          <Field
                            type="radio"
                            value={PLAYER_ADMISSION_STATUS.APPROVED}
                            name={`players[${index}].admissionStatus`}
                          />
                        </label>
                        <label
                          className={c(
                            "flex",
                            "flex-col",
                            "items-center",
                            "w-24",
                            "ml-6",
                            "text-xl",
                            "border",
                            "border-transparent",
                            "border-solid",
                            "rounded",
                            "px-4",
                            "pb-6",
                            "pt-2",
                            {
                              "text-error border-error":
                                player.admissionStatus ===
                                PLAYER_ADMISSION_STATUS.REJECTED,
                            }
                          )}
                        >
                          Reject
                          <Field
                            type="radio"
                            value={PLAYER_ADMISSION_STATUS.REJECTED}
                            name={`players[${index}].admissionStatus`}
                          />
                        </label>
                      </div>
                      <ErrorMessage
                        name={`players[${index}].admissionStatus`}
                        component="div"
                        className={c("text-xl", "text-error", "font-medium")}
                      />
                      {values.players.length > index + 1 ? (
                        <div className={c("flex", "justify-center", "my-8")}>
                          <hr
                            className={c(
                              "border-t",
                              "border-solid",
                              "border-white",
                              "w-3/5"
                            )}
                          />
                        </div>
                      ) : null}
                    </fieldset>
                  ))
                }
              </FieldArray>
              {error ? (
                <div className={c("flex", "justify-center", "text-center")}>
                  <Notification
                    className="mt-8"
                    state={NotificationState.ERROR}
                  >
                    {error}
                  </Notification>
                </div>
              ) : null}
            </ModalFormWrapper>
          )}
        </Formik>
      </div>
    </Modal>
  );
}
