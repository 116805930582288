import {
  Player,
  PlayerRound,
  Round,
  updateQuizPlayer,
} from "src/services/dataService";

type FormScoreValues = Record<string, number | "">;

export function getScoreKey({
  playerId,
  roundNumber,
  questionNumber,
}: {
  playerId: string;
  roundNumber: number;
  questionNumber: number;
}): string {
  return `player-${playerId}-round-${roundNumber}-question-${questionNumber}-score`;
}

export function getInitialPlayerScores(
  players: Player[],
  round: Round
): FormScoreValues {
  return players.reduce((initialScores, player) => {
    const playerRound = player.rounds.find(
      ({ roundNumber }) => roundNumber === round.roundNumber
    );

    round.questions.forEach((q) => {
      const score = playerRound?.answers?.find(
        (a) => a.questionNumber === q.questionNumber
      )?.score;
      initialScores[
        getScoreKey({
          playerId: player.id,
          roundNumber: round.roundNumber,
          questionNumber: q.questionNumber,
        })
      ] = score === 0 ? 0 : score || "";
    });
    return initialScores;
  }, {} as FormScoreValues);
}

export async function updatePlayerScores({
  quizId,
  players,
  round,
  scores,
  setAsMarked = false,
}: {
  quizId: string;
  players: Player[];
  round: Round;
  scores: FormScoreValues;
  setAsMarked?: boolean;
}): Promise<void[]> {
  return Promise.all(
    players.map((player) => {
      const playerRound = player.rounds.find(
        ({ roundNumber }) => roundNumber === round.roundNumber
      );
      const otherRounds = player.rounds.filter(
        ({ roundNumber }) => roundNumber !== round.roundNumber
      );
      if (playerRound) {
        const thisRound: PlayerRound = {
          ...playerRound,
          answers: playerRound.answers.map((answer) => ({
            ...answer,
            score:
              scores[
                getScoreKey({
                  playerId: player.id,
                  roundNumber: round.roundNumber,
                  questionNumber: answer.questionNumber,
                })
              ] || 0,
          })),
          ...(setAsMarked ? { isMarked: true } : {}),
        };
        return updateQuizPlayer(quizId, player.id, {
          rounds: [...otherRounds, thisRound],
        });
      }
      return Promise.resolve();
    })
  );
}
