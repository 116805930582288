import React from "react";
import c from "classnames";
import { WinningTeam } from "./WinningTeam";

export function QuizCompletedMessage() {
  return (
    <>
      <h2 className={c("text-3xl", "my-8")}>The Quiz has now finished</h2>
      <p className={c("text-2xl", "mb-8")}>
        The winning team is...
        <WinningTeam className={c("text-4xl", "text-primary")} />{" "}
        <span
          role="img"
          aria-label="Party Popper Emoji"
          className={c("ml-4", "text-4xl")}
        >
          🎉
        </span>
      </p>
    </>
  );
}
