import React, { ReactNode, PropsWithChildren, useContext } from "react";
import { AuthContext } from "src/services/authService";

interface QuizMasterContentProps {
  fallback?: ReactNode;
}

export function QuizMasterContent({
  children,
  fallback = null,
}: PropsWithChildren<QuizMasterContentProps>) {
  const { isQuizMaster } = useContext(AuthContext);

  if (isQuizMaster) {
    return <>{children}</>;
  }
  return <>{fallback}</>;
}
