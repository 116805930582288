import React, { PropsWithChildren } from "react";
import { Form } from "formik";
import {
  Modal as MaModal,
  ModalProps as MaModalProps,
} from "@material-ui/core";
import c from "classnames";

import { SubmitButton, Button } from "src/components/Button";

export type ModalProps = MaModalProps;

export function Modal({ children, ...props }: ModalProps) {
  return (
    <MaModal {...props}>
      <div
        className={c(
          "w-full",
          "h-full",
          "flex",
          "items-center",
          "justify-center"
        )}
      >
        <div
          className={c(
            "w-11/12",
            "sm:w-2/3",
            "overflow-y-scroll",
            "bg-secondary",
            "border",
            "border-solid",
            "border-white",
            "rounded",
            "flex",
            "flex-col",
            "items-center"
          )}
          style={{ maxHeight: "90%" }}
        >
          {children}
        </div>
      </div>
    </MaModal>
  );
}

interface ModalFormWrapperProps {
  onCancel: () => void;
  className?: string;
}

export function ModalFormWrapper({
  children,
  onCancel,
  className,
}: PropsWithChildren<ModalFormWrapperProps>) {
  return (
    <Form>
      <div className={c("p-4", className)}>{children}</div>
      <div className={c("w-full", "flex", "mt-12")}>
        <Button
          className={c(
            "qb-btn-secondary",
            "max-w-none",
            "rounded-none",
            "border-l-0",
            "border-b-0",
            "border-r-0"
          )}
          onClick={() => onCancel()}
        >
          Cancel
        </Button>
        <div className={c("border-l-2", "border-solid", "border-white")} />
        <SubmitButton className={c("max-w-none", "rounded-none")}>
          Submit
        </SubmitButton>
      </div>
    </Form>
  );
}

interface ModalConfirmationProps extends ModalProps {
  cancelText?: string;
  confirmText?: string;
  onCancel: () => void;
  onConfirm: () => void;
  processing?: boolean;
}

export function ModalConfirmation({
  cancelText = "Cancel",
  confirmText = "Confirm",
  onCancel,
  onConfirm,
  processing = false,
  children,
  ...modalProps
}: PropsWithChildren<ModalConfirmationProps>) {
  return (
    <Modal {...modalProps}>
      <>
        <div className={c("p-4")}>{children}</div>
        <div className={c("w-full", "flex", "mt-12")}>
          <Button
            className={c(
              "qb-btn-secondary",
              "max-w-none",
              "rounded-none",
              "border-l-0",
              "border-b-0",
              "border-r-0"
            )}
            disabled={processing}
            onClick={() => onCancel()}
          >
            {cancelText}
          </Button>
          <div className={c("border-l-2", "border-solid", "border-white")} />
          <Button
            className={c("max-w-none", "rounded-none")}
            loading={processing}
            onClick={() => onConfirm()}
          >
            {confirmText}
          </Button>
        </div>
      </>
    </Modal>
  );
}
