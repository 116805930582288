import React, { useContext, useState } from "react";
import c from "classnames"
import { useHistory } from "react-router-dom";
import ReplayIcon from '@material-ui/icons/Replay';

import { ERROR_MESSAGE, EDIT_QUIZ_URL } from "src/constants";
import { Button } from "src/components/Button";
import { Notification, NotificationState } from "src/components/Notification";
import { AuthContext } from "src/services/authService";
import { duplicateQuiz, Quiz } from "src/services/dataService";

interface DuplicateQuizButtonProps {
  quiz: Quiz
  className?: string
}

export function DuplicateQuizButton({ quiz, className }: DuplicateQuizButtonProps) {
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  if (user) {
    return (
      <div className={c("w-full", className)}>
        {error ? (
          <Notification className="mt-8" state={NotificationState.ERROR}>
            {ERROR_MESSAGE.DEFAULT}
          </Notification>
        ) : null}
        <Button
          loading={loading}
          onClick={() => {
            setError(false);
            setLoading(true);
            duplicateQuiz(quiz, user.uid)
              .then((quizDoc) => {
                history.push(`${EDIT_QUIZ_URL}/${quizDoc.id}`);
              })
              .catch(() => {
                setError(true);
                setLoading(false);
              });
          }}
        >
          Schedule Quiz Again<ReplayIcon fontSize="inherit" className={c("ml-4")} />
        </Button>
      </div>
    );
  }
  return null;
}
