import React, {
  useState,
  useEffect,
  createContext,
  PropsWithChildren,
} from "react";
import { Redirect } from "react-router-dom";

import { HOME_URL } from "src/constants";
import { LoadingPage } from "src/components/Loading";

import { Quiz, subscribeQuizData } from "./quizzes";
import {
  Player,
  subscribeQuizPlayers,
  PLAYER_ADMISSION_STATUS,
} from "./players";

interface QuizContextState {
  quiz: Quiz | null;
  players: Player[];
  approvedPlayers: Player[];
}

const initialState = { quiz: null, players: [], approvedPlayers: [] };

export const QuizContext = createContext<QuizContextState>(initialState);

interface QuizProviderProps {
  quizId: string;
}

export function QuizProvider({
  quizId,
  children,
}: PropsWithChildren<QuizProviderProps>) {
  const [quiz, setQuiz] = useState<Quiz | null>(null);
  const [players, setPlayers] = useState<Player[]>([]);
  const [approvedPlayers, setApprovedPlayers] = useState<Player[]>([]);
  const [loadingQuiz, setLoadingQuiz] = useState(true);
  const [loadingPlayers, setLoadingPlayers] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    setLoadingQuiz(true);
    setLoadingPlayers(true);
    setError(false);

    const unsubscribeQuiz = subscribeQuizData(
      quizId,
      (subscribedQuiz) => {
        setQuiz(subscribedQuiz);
        setLoadingQuiz(false);
      },
      () => {
        setError(true);
        setLoadingQuiz(false);
      }
    );

    const unsubscribePlayers = subscribeQuizPlayers(
      quizId,
      (subscribedPlayers) => {
        setPlayers(subscribedPlayers);
        setApprovedPlayers(
          subscribedPlayers.filter(
            (p) => p.admissionStatus === PLAYER_ADMISSION_STATUS.APPROVED
          )
        );
        setLoadingPlayers(false);
      },
      () => {
        setError(true);
        setLoadingPlayers(false);
      }
    );

    return function () {
      unsubscribeQuiz();
      unsubscribePlayers();
    };
  }, [quizId]);

  if (!quizId || error) {
    return <Redirect to={HOME_URL} />;
  }

  if (loadingQuiz || loadingPlayers) {
    return <LoadingPage />;
  }

  if (quiz) {
    return (
      <QuizContext.Provider value={{ quiz, players, approvedPlayers }}>
        {children}
      </QuizContext.Provider>
    );
  }

  return null;
}
