import React, { useState } from "react";
import { Link } from "react-router-dom";
import c from "classnames";
import { Formik, Form } from "formik";

import { Round, Player } from "src/services/dataService";
import { SubmitButton } from "src/components/Button";
import { HorizontalBreak } from "src/components/HorizontalBreak";
import { Toggle } from "src/components/Toggle";
import { ERROR_MESSAGE } from "src/constants";
import { Notification, NotificationState } from "src/components/Notification";

import { RoundQuestion } from "./RoundQuestion";
import { getInitialPlayerScores, updatePlayerScores } from "./utils";

interface RoundDetailsProps {
  round: Round;
  quizUrl: string;
  quizId: string;
  approvedPlayers: Player[];
}

export function RoundDetails({
  round,
  quizUrl,
  quizId,
  approvedPlayers,
}: RoundDetailsProps) {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [showAnswers, setShowAnswers] = useState(false);
  const submittedPlayers = approvedPlayers.filter(
    (p) =>
      p.rounds.find((r) => r.roundNumber === round.roundNumber)?.isSubmitted
  );
  const totalPlayers = approvedPlayers.length;
  const totalSubmitted = submittedPlayers.length;

  const initialFormValues = getInitialPlayerScores(approvedPlayers, round);

  return (
    <Formik
      initialValues={initialFormValues}
      onSubmit={async (values, helpers) => {
        setError("");
        setSuccess(false);
        try {
          await updatePlayerScores({
            quizId,
            players: approvedPlayers,
            round,
            scores: values,
            setAsMarked: true,
          });
          setSuccess(true);
        } catch {
          setError(ERROR_MESSAGE.DEFAULT);
        }
        helpers.setSubmitting(false);
      }}
    >
      <Form>
        <h2 className={c("text-3xl")}>{round.title} - Questions</h2>

        {round.description ? (
          <p className={c("text-2xl", "my-4")}>{round.description}</p>
        ) : null}

        {totalPlayers === totalSubmitted ? (
          <p className={c("text-xl", "my-6")}>
            All teams have submitted their answers
          </p>
        ) : (
          <p className={c("text-xl", "my-6")}>
            <strong>{totalSubmitted}</strong> /{" "}
            <strong className={c("mr-2")}>{totalPlayers}</strong> teams have
            submitted answers
          </p>
        )}

        <Toggle
          label="Show answers (only visible to you)"
          onClick={() => {
            setShowAnswers((state) => !state);
          }}
        />

        <ol
          className={c(
            "flex",
            "justify-center",
            "flex-wrap",
            "w-full",
            "mt-16",
            "items-end"
          )}
        >
          {round.questions.map((question) => (
            <RoundQuestion
              key={`host-round-${round.roundNumber}-question-${question.questionNumber}`}
              roundNumber={round.roundNumber}
              question={question}
              showAnswer={showAnswers}
              players={approvedPlayers}
            />
          ))}
        </ol>

        <HorizontalBreak className={c("w-4/5")} noMargin={true} />

        <div className={c("flex", "flex-col", "items-center", "mt-8")}>
          {showAnswers && error ? (
            <Notification className="mb-8" state={NotificationState.ERROR}>
              {error}
            </Notification>
          ) : null}
          {showAnswers && success ? (
            <Notification className="mb-8" state={NotificationState.SUCCESS}>
              Scores updated
            </Notification>
          ) : null}
          {showAnswers ? <SubmitButton>Submit scores</SubmitButton> : null}
          <Link
            to={quizUrl}
            className={c(
              "qb-a",
              "py-4",
              "px-8",
              "text-2xl",
              "w-full",
              "max-w-lg",
              { "mt-8": showAnswers }
            )}
          >
            Back to other rounds
          </Link>
        </div>
      </Form>
    </Formik>
  );
}
