import { createMuiTheme } from "@material-ui/core/styles";
import tailwindConfig from "./tailwind.config";
const resolveConfig = require("tailwindcss/resolveConfig");

const twConfig = resolveConfig(tailwindConfig);

export const muiTheme = createMuiTheme({
  overrides: {
    MuiDialogActions: {
      root: {
        backgroundColor: twConfig.theme.colors.secondary.default,
      },
    },
  },
  palette: {
    primary: {
      main: twConfig.theme.colors.primary.default,
    },
    secondary: {
      light: twConfig.theme.colors.secondary.light,
      main: twConfig.theme.colors.secondary.default,
    },
    error: {
      main: twConfig.theme.colors.error.default,
    },
    success: {
      main: twConfig.theme.colors.success,
    },
  },
});
