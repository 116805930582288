import React, { useState } from "react";
import { Formik, FieldArray } from "formik";
import c from "classnames";
import { Fab } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { useTheme } from "@material-ui/core/styles";
import { User } from "firebase";

import { Input, TextArea } from "src/components/Input";
import { FormToggle } from "src/components/Toggle";
import { Notification, NotificationState } from "src/components/Notification";
import { Round, updateQuiz, Question } from "src/services/dataService";
import { roundValidation } from "src/validationSchemas";
import { ERROR_MESSAGE } from "src/constants";
import { ModalFormWrapper } from "src/components/Modal";
import { QuestionImageUpload } from "src/components/QuestionImageUpload";

interface EditRoundState {
  isEdit: boolean;
  roundNumber: number | null;
}

interface EditRoundFormProps {
  quizId: string;
  rounds: Round[];
  user: User;
  editState?: EditRoundState;
  onCancel: () => void;
  onUpdated: (rounds: Round[]) => void;
}

const newQuestion: Partial<Question> = {
  text: "",
  answer: "",
  imageIncluded: false,
  imageUrl: "",
};

export function EditRoundForm({
  onCancel,
  onUpdated,
  quizId,
  rounds,
  user,
  editState,
}: EditRoundFormProps) {
  const [errorMessage, setErrorMessage] = useState("");
  const theme = useTheme();

  let thisRound: Round;
  let initialValues: Partial<Round>;

  if (editState?.isEdit) {
    thisRound = rounds.find((r) => r.roundNumber === editState.roundNumber)!;
    initialValues = {
      title: thisRound.title ?? "",
      description: thisRound.description ?? "",
      questions: thisRound.questions ?? ([newQuestion] as Question[]),
    };
  } else {
    initialValues = {
      title: "",
      description: "",
      questions: [newQuestion] as Question[],
    };
  }

  return (
    <Formik
      initialValues={{
        title: initialValues.title || "",
        description: initialValues.description || "",
        questions: initialValues.questions || ([newQuestion] as Question[]),
      }}
      validationSchema={roundValidation}
      onSubmit={async ({ title, description, questions }) => {
        setErrorMessage("");
        let updatedRounds: Round[];

        if (editState?.isEdit) {
          const otherRounds = rounds.filter(
            (r) => r.roundNumber !== editState.roundNumber
          );
          updatedRounds = [
            ...otherRounds,
            {
              ...thisRound,
              title,
              description,
              questions: questions.map((question, index) => ({
                ...question,
                questionNumber: index + 1,
              })),
            },
          ];
          updatedRounds.sort((a, b) => a.roundNumber - b.roundNumber);
        } else {
          updatedRounds = [
            ...rounds,
            {
              title,
              description,
              roundNumber: rounds.length + 1,
              questions: questions.map((question, index) => ({
                ...question,
                questionNumber: index + 1,
              })),
            },
          ];
        }
        try {
          await updateQuiz(quizId, {
            rounds: updatedRounds,
          });
          onUpdated(updatedRounds);
        } catch {
          setErrorMessage(ERROR_MESSAGE.DEFAULT);
        }
      }}
    >
      {({ values }) => (
        <ModalFormWrapper onCancel={onCancel}>
          <div className={c("p-4")}>
            <Input name="title" label="Round name" id="add-round-title" />
            <TextArea
              name="description"
              label="Description (Optional)"
              id="add-round-description"
              className="mt-4"
              rows={2}
            />
            {errorMessage ? (
              <Notification className="mt-8" state={NotificationState.ERROR}>
                {errorMessage}
              </Notification>
            ) : null}
            <FieldArray name="questions">
              {(arrayHelpers) => (
                <>
                  {values.questions.map(({ imageIncluded }, index) => (
                    <fieldset
                      key={`add-round-question-${index}`}
                      className={c(
                        "border-t",
                        "border-white",
                        "border-dashed",
                        "px-4",
                        "sm:px-8",
                        "pt-6",
                        "mt-10",
                        "relative",
                        {
                          "border-b pb-16":
                            values.questions.length === index + 1,
                        }
                      )}
                    >
                      <legend className={c("text-2xl", "px-3")}>
                        Question {index + 1}
                      </legend>

                      <div
                        className={c(
                          "absolute",
                          "bg-secondary",
                          "top-0",
                          "right-0",
                          "px-3"
                        )}
                      >
                        <Fab
                          style={{
                            backgroundColor: theme.palette.error.main,
                          }}
                          size="small"
                          aria-label={`Delete question ${index + 1}`}
                          onClick={() => {
                            arrayHelpers.remove(index);
                          }}
                        >
                          <DeleteForeverIcon />
                        </Fab>
                      </div>

                      <TextArea
                        id={`edit-form-question-${index}-text`}
                        name={`questions[${index}].text`}
                        label="Question (Optional, only visible to you)"
                        rows={1}
                      />
                      <TextArea
                        id={`edit-form-answer-${index}`}
                        name={`questions[${index}].answer`}
                        label="Answer"
                        className={c("mt-4")}
                        rows={1}
                      />
                      <div className={c("mt-4", "text-2xl")}>
                        <FormToggle
                          name={`questions[${index}].imageIncluded`}
                          label="Do you want to include an image with this question?"
                        />
                      </div>
                      {imageIncluded ? (
                        <div>
                          <QuestionImageUpload
                            name={`questions[${index}].imageUrl`}
                            user={user}
                            quizId={quizId}
                            questionNumber={index + 1}
                            roundNumber={rounds.length + 1}
                          />
                        </div>
                      ) : null}
                    </fieldset>
                  ))}
                  <div className={c("w-full", "flex", "justify-center")}>
                    <div className={c("-mt-6", "px-3", "bg-secondary")}>
                      <Fab
                        color="primary"
                        aria-label="Add question"
                        variant="extended"
                        onClick={() => arrayHelpers.push(newQuestion)}
                      >
                        <AddIcon />
                        <div className="ml-2">Add another question</div>
                      </Fab>
                    </div>
                  </div>
                </>
              )}
            </FieldArray>
          </div>
        </ModalFormWrapper>
      )}
    </Formik>
  );
}
