import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import c from "classnames";
import BlockIcon from "@material-ui/icons/Block";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import {
  Quiz,
  updateQuiz,
  QUIZ_STATUS,
  Player,
} from "src/services/dataService";
import { Button } from "src/components/Button";
import { HorizontalBreak } from "src/components/HorizontalBreak";
import { ModalConfirmation } from "src/components/Modal";
import { ERROR_MESSAGE } from "src/constants";
import { Notification, NotificationState } from "src/components/Notification";
import { ResultsTable } from "src/components/ResultsTable";
import { QuizCompletedMessage } from "src/components/QuizCompletedMessage";
import { ManagePlayersButton } from "src/components/ManagePlayers";
import { AuthContext } from "src/services/authService";

interface ConfirmEndQuizModalProps {
  isOpen: boolean;
  quizId: string;
  onCancel: () => void;
  onComplete: () => void;
}

function ConfirmEndQuizModal({
  isOpen,
  quizId,
  onCancel,
  onComplete,
}: ConfirmEndQuizModalProps) {
  const [error, setError] = useState("");
  const labelledBy = "confirm-end-quiz-title";
  return (
    <ModalConfirmation
      aria-labelledby={labelledBy}
      open={isOpen}
      confirmText="End Quiz"
      onCancel={() => {
        setError("");
        onCancel();
      }}
      onConfirm={async () => {
        setError("");
        try {
          await updateQuiz(quizId, { status: QUIZ_STATUS.COMPLETED });
          onComplete();
        } catch {
          setError(ERROR_MESSAGE.DEFAULT);
        }
      }}
    >
      <div className={"text-center"}>
        <h2 className={c("text-3xl", "mt-8")} id={labelledBy}>
          Are you sure you want to end this quiz?
        </h2>
        {error ? (
          <Notification className="mt-8" state={NotificationState.ERROR}>
            {error}
          </Notification>
        ) : null}
      </div>
    </ModalConfirmation>
  );
}
interface QuizDetailsProps {
  quiz: Quiz;
  quizUrl: string;
  players: Player[];
  approvedPlayers: Player[];
}

export function QuizDetails({
  quiz,
  quizUrl,
  players,
  approvedPlayers,
}: QuizDetailsProps) {
  const { user } = useContext(AuthContext);
  const [showEndQuiz, setShowEndQuiz] = useState(false);
  const isCompleted = quiz.status === QUIZ_STATUS.COMPLETED;

  return (
    <div
      className={c("w-full", "flex", "flex-col", "items-center", "text-2xl")}
    >
      {isCompleted ? (
        <div className={c("mb-12")}>
          <QuizCompletedMessage />
          <h2 id="leader-board" className={c("text-3xl", "mt-12", "my-8")}>
            Results
          </h2>
          <ResultsTable />
        </div>
      ) : (
        <>
          <p>
            Welcome to your quiz,{" "}
            <span className={c("font-medium", "text-primary")}>
              {user?.displayName}
            </span>
            .<br />
            From here you can:
          </p>
          <ul
            className={c(
              "list-disc",
              "text-xl",
              "pl-8",
              "text-left",
              "max-w-2xl",
              "my-8"
            )}
          >
            <li className={c("mb-2")}>
              Access details for all the{" "}
              <a href="#rounds" className={c("text-primary", "underline")}>
                rounds
              </a>
            </li>
            <li className={c("mb-2")}>
              View the{" "}
              <a
                href="#leader-board"
                className={c("text-primary", "underline")}
              >
                Leader Board
              </a>
            </li>
            <li className={c("mb-2")}>
              Manage player access or end the quiz from the buttons below
            </li>
          </ul>
          <ManagePlayersButton quiz={quiz} players={players} />
          <Button onClick={() => setShowEndQuiz(true)} className={c("my-4")}>
            End Quiz <BlockIcon fontSize="inherit" className={c("ml-4")} />
          </Button>
          <ConfirmEndQuizModal
            isOpen={showEndQuiz}
            quizId={quiz.id}
            onCancel={() => setShowEndQuiz(false)}
            onComplete={() => setShowEndQuiz(false)}
          />
        </>
      )}

      <HorizontalBreak className={c("w-4/5")} />

      <h2 id="rounds" className={c("text-3xl", "my-8")}>
        Rounds
      </h2>
      <p className={c("text-xl", "mb-8")}>
        Click on a round to see your questions and teams' answer sheets
      </p>
      <ol>
        {quiz.rounds.map((round, index) => {
          return (
            <li
              key={`host-quiz-round-link-${index}`}
              className={c("flex", "flex-col", "items-center")}
            >
              <Link
                to={`${quizUrl}/round/${round.roundNumber}`}
                className={c(
                  "qb-a",
                  "text-2xl",
                  "mb-8",
                  "w-full",
                  "max-w-lg",
                  "px-16",
                  "relative"
                )}
                style={{ minWidth: "20rem" }}
              >
                {round.title}
                {isAllPlayersRoundMarked(approvedPlayers, round.roundNumber) ? (
                  <div
                    className={c("text-success", "absolute")}
                    style={{
                      top: "0.6rem",
                      right: "2.5rem",
                    }}
                  >
                    <CheckBoxIcon
                      color="inherit"
                      fontSize="inherit"
                      className={c("ml-4", "-mr-6")}
                    />
                  </div>
                ) : null}
              </Link>
            </li>
          );
        })}
      </ol>

      {isCompleted ? null : (
        <>
          <HorizontalBreak className={c("w-4/5")} />
          <h2 id="leader-board" className={c("text-3xl", "my-8")}>
            Leader Board
          </h2>
          <div className={c("flex", "justify-center", "mb-8")}>
            <p className={c("text-xl", "max-w-2xl")}>
              This is only visible to you. Teams will see their own scores, but
              will not see other teams' scores until you end the quiz.
            </p>
          </div>
          <ResultsTable />
        </>
      )}
    </div>
  );
}

function isAllPlayersRoundMarked(
  players: Player[],
  roundNumber: number
): boolean {
  return (
    players.length > 0 &&
    players.every((player) => {
      const playerRound = player.rounds.find(
        (r) => r.roundNumber === roundNumber
      );
      return playerRound && playerRound.isMarked;
    })
  );
}
