import * as firebase from "firebase/app";

type DocumentData = firebase.firestore.DocumentData;
type DocumentSnapshot = firebase.firestore.DocumentSnapshot<DocumentData>;

interface UserData {
  isQuizMaster: boolean;
  displayName: string;
}

export function createUserData(uid: string, data: UserData) {
  return firebase.firestore().collection("users").doc(uid).set(data);
}

export function getUserData(uid: string) {
  return firebase.firestore().collection("users").doc(uid).get();
}

export function subscribeUserData(
  uid: string,
  onNext: (snapshot: DocumentSnapshot) => void,
  onError?: (error: Error) => void
) {
  return firebase
    .firestore()
    .collection("users")
    .doc(uid)
    .onSnapshot(onNext, onError);
}
