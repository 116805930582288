import React from "react"
import { CircularProgress, CircularProgressProps } from "@material-ui/core"

export function Loading(props: CircularProgressProps) {
  return <CircularProgress color="inherit" size={30} {...props} />
}

export function LoadingPage() {
  return (
    <div className="p-32">
      <Loading size={50} />
    </div>
  );
}
