import React from "react";
import { Link } from "react-router-dom";
import c from "classnames";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import { Quiz, Player, QUIZ_STATUS } from "src/services/dataService";
import { ResultsTable } from "src/components/ResultsTable";
import { QuizCompletedMessage } from "src/components/QuizCompletedMessage";

interface QuizDetailsProps {
  quiz: Quiz;
  quizUrl: string;
  player: Player;
}

export function QuizDetails({ quiz, quizUrl, player }: QuizDetailsProps) {
  const isCompleted = quiz.status === QUIZ_STATUS.COMPLETED;

  return (
    <div>
      {isCompleted ? (
        <div className={c("mb-24")}>
          <QuizCompletedMessage />
          <p className={c("text-2xl", "mb-12")}>See full results below</p>
          <ResultsTable />
        </div>
      ) : null}
      <h2 className={c("text-3xl", "my-8")}>Rounds</h2>
      <p className={c("text-xl", "mb-8")}>
        Click on a round to {isCompleted ? "see" : "fill out"} your answers
      </p>
      <ol>
        {quiz.rounds.map((round, index) => {
          const playerRound = player.rounds?.find(
            ({ roundNumber }) => roundNumber === round.roundNumber
          );
          return (
            <li
              key={`quiz-round-link-${index}`}
              className={c("flex", "flex-col", "items-center")}
            >
              <Link
                to={`${quizUrl}/round/${round.roundNumber}`}
                className={c(
                  "qb-a",
                  "text-2xl",
                  "mb-8",
                  "w-full",
                  "max-w-lg",
                  "px-16",
                  "relative"
                )}
                style={{ minWidth: "20rem" }}
              >
                {round.title}
                {playerRound?.isSubmitted ? (
                  <div
                    className={c("text-success", "absolute")}
                    style={{
                      top: playerRound.isMarked ? 0 : "0.6rem",
                      right: "2.5rem",
                    }}
                  >
                    <CheckBoxIcon
                      color="inherit"
                      fontSize="inherit"
                      className={c("ml-4", "-mr-6")}
                    />
                    {playerRound.isMarked ? (
                      <div className={c("text-sm", "-mr-6")}>
                        Total:{" "}
                        {playerRound.answers.reduce((acc, cur) => {
                          return acc + (cur.score || 0);
                        }, 0)}
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </Link>
            </li>
          );
        })}
      </ol>
    </div>
  );
}
