import React, { useContext } from "react";
import c from "classnames";
import {
  useParams,
  Redirect,
  Switch,
  Route,
  useLocation,
  Link,
} from "react-router-dom";

import { AuthContext } from "src/services/authService";
import {
  QuizProvider,
  QuizContext,
  PLAYER_ADMISSION_STATUS,
} from "src/services/dataService";
import { HOME_URL, HOST_QUIZ_URL, PLAYER_QUIZ_URL } from "src/constants";
import { ProtectedRoute } from "src/routes";
import { PageLayout } from "src/components/PageLayout";

import { QuizDetails } from "./QuizDetails";
import { RoundDetails } from "./RoundDetails";

function PlayerQuiz() {
  const { user } = useContext(AuthContext);
  const { quiz, players } = useContext(QuizContext);
  const { pathname } = useLocation();

  if (quiz?.createdBy === user?.uid) {
    return <Redirect to={`${HOST_QUIZ_URL}/${quiz?.id}`} />;
  }

  const quizUrl = `${PLAYER_QUIZ_URL}/${quiz!.id}`;
  const userPlayer = players
    .filter(
      (player) => player.admissionStatus === PLAYER_ADMISSION_STATUS.APPROVED
    )
    .find((player) => player.id === user?.uid);

  if (userPlayer) {
    return (
      <PageLayout
        heading={quiz?.title}
        action={
          pathname === quizUrl ? undefined : (
            <Link to={quizUrl} className={c("qb-a", "w-full")}>
              Back
            </Link>
          )
        }
        className="w-full"
      >
        <Switch>
          <ProtectedRoute exact={true} path={quizUrl}>
            <QuizDetails quiz={quiz!} quizUrl={quizUrl} player={userPlayer} />
          </ProtectedRoute>

          {quiz?.rounds.map((round, index) => (
            <ProtectedRoute
              key={`quiz-round-route-${index}`}
              exact={true}
              path={`${quizUrl}/round/${round.roundNumber}`}
            >
              <RoundDetails
                quizId={quiz.id}
                quizUrl={quizUrl}
                round={round}
                player={userPlayer}
              />
            </ProtectedRoute>
          ))}

          <Route>
            <Redirect to={quizUrl} />
          </Route>
        </Switch>
      </PageLayout>
    );
  }

  return <Redirect to={HOME_URL} />;
}

export function PlayerQuizPage() {
  const { quizId = "" } = useParams();

  return (
    <QuizProvider quizId={quizId}>
      <PlayerQuiz />
    </QuizProvider>
  );
}
