import React from "react";
import c from "classnames";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

interface StartTimeDisplayProps {
  scheduledStartUnix: number;
}

export function ScheduledStartTimeDisplay({ scheduledStartUnix }: StartTimeDisplayProps) {
  const startDate = dayjs.unix(scheduledStartUnix);
  const isRunningLate = startDate.isBefore(dayjs());

  if (scheduledStartUnix && startDate.isValid()) {
    return (
      <>
        <p className={c("text-2xl", { "text-error": isRunningLate })}>
          {isRunningLate ? "Due to start" : "Starting"}{" "}
          {dayjs().to(startDate)}
        </p>
        <StartTimeDisplay scheduledStartUnix={scheduledStartUnix} />
      </>
    );
  }
  return null;
}

export function StartTimeDisplay({ scheduledStartUnix }: StartTimeDisplayProps) {
  const startDate = dayjs.unix(scheduledStartUnix);

  if (scheduledStartUnix && startDate.isValid()) {
    return (
      <>
        <p className={c("text-lg")}>{startDate.format("llll")}</p>
      </>
    );
  }
  return null;
}
