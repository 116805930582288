import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import { ERROR_MESSAGE, CREATE_QUIZ_URL } from "src/constants";
import { Button } from "src/components/Button";
import { Notification, NotificationState } from "src/components/Notification";
import { AuthContext } from "src/services/authService";
import { createQuiz } from "src/services/dataService";

export function CreateQuizButton() {
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  if (user) {
    return (
      <>
        {error ? (
          <Notification className="mt-8" state={NotificationState.ERROR}>
            {ERROR_MESSAGE.DEFAULT}
          </Notification>
        ) : null}
        <Button
          className="qb-btn mb-6 sm:w-1/2"
          loading={loading}
          onClick={() => {
            setError(false);
            setLoading(true);
            createQuiz(user.uid)
              .then((quizDoc) => {
                history.push(`${CREATE_QUIZ_URL}/${quizDoc.id}`);
              })
              .catch(() => {
                setError(true);
                setLoading(false);
              });
          }}
        >
          Create new quiz
        </Button>
      </>
    );
  }
  return null;
}
