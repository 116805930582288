import React from "react";
import { useField } from "formik";
import MuiSwitch from "@material-ui/core/Switch";
import FormControlLabel, {
  FormControlLabelProps,
} from "@material-ui/core/FormControlLabel";

export function Toggle({
  checked,
  className,
  ...props
}: Omit<FormControlLabelProps, "control">) {
  return (
    <FormControlLabel
      control={
        <MuiSwitch color="primary" checked={checked} className={className} />
      }
      {...props}
    />
  );
}

export interface FormToggleProps
  extends Omit<FormControlLabelProps, "control"> {
  name: string;
  value?: any;
}

export function FormToggle({
  name,
  labelPlacement = "end",
  className,
  ...props
}: FormToggleProps) {
  const [field] = useField(name);

  return (
    <FormControlLabel
      control={
        <MuiSwitch
          color="primary"
          checked={field.value}
          className={className}
        />
      }
      labelPlacement={labelPlacement}
      {...field}
      {...props}
    />
  );
}
