import React, { PropsWithChildren, ButtonHTMLAttributes } from "react";
import { useFormikContext } from "formik";
import c from "classnames";
import { Loading } from "src/components/Loading";

interface ButtonProps extends PropsWithChildren<ButtonHTMLAttributes<unknown>> {
  loading?: boolean;
}

export function Button({
  children,
  className,
  loading = false,
  ...props
}: ButtonProps) {
  return (
    <button
      type="button"
      className={c("qb-btn", "sm:text-2xl", className)}
      disabled={loading}
      {...props}
    >
      {loading ? <Loading size={25} /> : children}
    </button>
  );
}

export function SubmitButton({
  children = "Submit",
  ...props
}: PropsWithChildren<ButtonHTMLAttributes<unknown>>) {
  const { isSubmitting, isValid, submitCount } = useFormikContext();
  return (
    <Button
      type="submit"
      disabled={isSubmitting || (!isValid && !!submitCount)}
      loading={isSubmitting}
      {...props}
    >
      {children}
    </Button>
  );
}
