import React, { useContext, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { Formik, Form } from "formik";
import c from "classnames";

import { Input } from "src/components/Input";
import { SignOut } from "src/components/SignOut";
import { HOME_URL, CREATE_ACCOUNT_URL, ERROR_MESSAGE } from "src/constants";
import { AuthContext, signIn } from "src/services/authService";
import { loginValidation } from "src/validationSchemas";
import { SubmitButton } from "src/components/Button";
import { Notification, NotificationState } from "src/components/Notification";
import { PageLayout } from "src/components/PageLayout";

import { PasswordResetModal } from "./PasswordResetModal";

interface PasswordResetState {
  showForm: boolean;
  email: string;
  resetLinkSent: boolean;
}

const initialPasswordResetState = {
  showForm: false,
  email: "",
  resetLinkSent: false,
};

export function Login() {
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordResetState, setPasswordResetState] = useState<
    PasswordResetState
  >(initialPasswordResetState);

  return (
    <PageLayout heading="Login" className={c("w-4/5", "max-w-md", "py-12")}>
      {user ? (
        <SignOut />
      ) : (
        <>
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={loginValidation}
            onSubmit={async ({ email, password }) => {
              setErrorMessage("");
              try {
                await signIn(email, password);
                history.push(HOME_URL);
              } catch (error) {
                if (error?.message) {
                  setErrorMessage(error.message);
                } else {
                  setErrorMessage(ERROR_MESSAGE.DEFAULT);
                }
              }
            }}
          >
            {({ values }) => (
              <Form>
                <Input
                  name="email"
                  label="Email"
                  id="login-email"
                  autoComplete="on"
                />
                <Input
                  name="password"
                  label="Password"
                  id="login-password"
                  className="mt-4"
                  type="password"
                  extra={
                    <button
                      type="button"
                      className={c("text-primary", "hover:text-primary-dark")}
                      onClick={() => {
                        setPasswordResetState({
                          showForm: true,
                          email: values.email,
                          resetLinkSent: false,
                        });
                      }}
                    >
                      Forgot password?
                    </button>
                  }
                />
                {errorMessage ? (
                  <Notification
                    className="mt-8"
                    state={NotificationState.ERROR}
                  >
                    {errorMessage}
                  </Notification>
                ) : null}
                {passwordResetState.resetLinkSent ? (
                  <Notification
                    className="mt-8"
                    state={NotificationState.SUCCESS}
                  >
                    <p>
                      Password reset link sent to <br />
                      <span className={c("text-secondary", "font-medium")}>
                        {passwordResetState.email}
                      </span>
                      <br /> Please check your email
                    </p>
                  </Notification>
                ) : null}
                <SubmitButton className="mt-8">Login</SubmitButton>
              </Form>
            )}
          </Formik>
          <section className="mt-10 w-full flex flex-col items-center">
            <p>Not got an account?</p>
            <Link to={CREATE_ACCOUNT_URL} className="qb-a mt-4">
              Create an account instead
            </Link>
          </section>

          <PasswordResetModal
            email={passwordResetState.email}
            onCancel={() => {
              setPasswordResetState(initialPasswordResetState);
            }}
            onSuccess={(email: string) => {
              setPasswordResetState({
                showForm: false,
                email,
                resetLinkSent: true,
              });
            }}
            open={passwordResetState.showForm}
          />
        </>
      )}
    </PageLayout>
  );
}
