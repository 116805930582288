import React, { useContext } from "react";
import c from "classnames";

import { PageLayout } from "src/components/PageLayout";
import { QuizListByUserAndStatus } from "src/components/QuizList";
import { AuthContext } from "src/services/authService";
import { QUIZ_STATUS } from "src/services/dataService";

export function MyQuizzes() {
  const { user, isQuizMaster } = useContext(AuthContext);

  if (user && isQuizMaster) {
    return (
      <PageLayout heading="My Quizzes" className={c("w-full", "max-w-2xl")}>
        <h2 className={c("text-3xl")}>Here's all the quizzes created by you</h2>
        <QuizListByUserAndStatus
          uid={user.uid}
          quizStatuses={[
            QUIZ_STATUS.COMPLETED,
            QUIZ_STATUS.SCHEDULED,
            QUIZ_STATUS.STARTED,
          ]}
        />
      </PageLayout>
    );
  }

  return null;
}
