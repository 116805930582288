import React, { InputHTMLAttributes } from "react";
import c from "classnames";
import { useField } from "formik";

export interface NumberInputProps
  extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
}

export function NumberInput({ name, className, ...props }: NumberInputProps) {
  const [field, meta] = useField(name);

  return (
    <input
      autoComplete="off"
      type="number"
      className={c(
        "w-16",
        "py-2",
        "px-1",
        "rounded",
        "text-secondary",
        "text-center",
        "text-lg",
        "border-2",
        "border-transparent",
        {
          "border-error": meta.error && meta.touched,
        }
      )}
      {...field}
      {...props}
    />
  );
}
