import React, { useState } from "react";
import c from "classnames";
import PeopleIcon from "@material-ui/icons/People";

import { Button } from "src/components/Button";
import { PlayerApprovalModal } from "src/components/PlayerApprovalModal";
import { Quiz, Player } from "src/services/dataService";

interface ManagePlayersButtonProps {
  quiz: Quiz
  players: Player[]
}

export function ManagePlayersButton({ quiz, players }: ManagePlayersButtonProps) {
  const [showPlayerForm, setShowPlayerForm] = useState(false);

  if (quiz) {
    return (
      <>
        <Button
          className={c("mt-4")}
          onClick={() => setShowPlayerForm(true)}
          disabled={(players ?? []).length === 0}
        >
          Manage Players <PeopleIcon fontSize="inherit" className={c("ml-4")} />
        </Button>
        <PlayerApprovalModal
          quiz={quiz}
          players={players}
          onCancel={() => setShowPlayerForm(false)}
          onUpdated={() => setShowPlayerForm(false)}
          open={showPlayerForm}
        />
      </>
    );
  }

  return null;
}
