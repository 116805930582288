import { object, string, array, date, number, boolean } from "yup";
import dayjs from "dayjs";

import { PLAYER_ADMISSION_STATUS, Question } from "src/services/dataService";

export const emailValidation = object().shape({
  email: string().email().required().label("Email"),
});

export const loginValidation = object()
  .shape({
    password: string().min(6).required().label("Password"),
  })
  .concat(emailValidation);

export const createAccountValiadtion = object()
  .shape({
    username: string().required().label("Username"),
  })
  .concat(loginValidation);

const questionValidation = object().shape({
  text: string().label("Question"),
  answer: string().required().label("Answer"),
  imageIncluded: boolean(),
  imageUrl: string().when("imageIncluded", {
    is: true,
    then: string().required(
      "Need to upload an image, or mark as no image included"
    ),
    otherwise: string(),
  }),
});

export const roundValidation = object().shape({
  title: string().required().label("Round name"),
  description: string(),
  questions: array().of(questionValidation).required().label("Questions"),
});

export const quizValidation = object().shape({
  title: string().required().label("Quiz title"),
  scheduledStart: date()
    .min(dayjs().toDate(), "Start date cannot be in the past")
    .required()
    .label("Start date"),
  rounds: array().of(roundValidation).required().label("Rounds"),
});

export const joinQuizValidation = object().shape({
  teamName: string().required().label("Team Name"),
});

const playerApprovalValidation = object().shape({
  admissionStatus: string()
    .oneOf(
      [PLAYER_ADMISSION_STATUS.APPROVED, PLAYER_ADMISSION_STATUS.REJECTED],
      "You need to either approve or reject the team"
    )
    .required()
    .label("Approval status"),
});

export const playersApprovalValidation = object().shape({
  players: array()
    .of(playerApprovalValidation)
    .required()
    .label("Team approval status"),
});

export const getScoresValidation = (questions: Question[]) => {
  let schema = object().shape({});
  questions.forEach(({ questionNumber }) => {
    schema = schema.concat(
      object().shape({
        [`score${questionNumber}`]: number()
          .required()
          .label(`Score for Question ${questionNumber}`),
      })
    );
  });
  return schema;
};
