import React, { useState } from "react";
import c from "classnames";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CancelIcon from "@material-ui/icons/Cancel";

import {
  Round,
  Player,
  PlayerRound,
  PlayerAnswer,
  updateQuizPlayer,
  Question,
} from "src/services/dataService";
import { Formik, Form } from "formik";
import { TextArea } from "src/components/Input";
import { Button } from "src/components/Button";
import { ModalConfirmation } from "src/components/Modal";
import { Link } from "react-router-dom";
import { ERROR_MESSAGE } from "src/constants";
import { Notification, NotificationState } from "src/components/Notification";

interface FormValues {
  [key: string]: string;
}

interface AnsweredQuestionsSummaryProps {
  values: FormValues;
  questions: Question[];
}

function AnsweredQuestionsSummary({
  values,
  questions,
}: AnsweredQuestionsSummaryProps) {
  const totalAnswers = Object.keys(values).filter(
    (value) => values[value]?.length > 0
  ).length;
  if (totalAnswers === questions.length) {
    return (
      <p className={c("text-2xl", "mt-8", "mb-4")}>All questions answered</p>
    );
  }

  if (totalAnswers < questions.length) {
    return (
      <p className={c("text-2xl", "mt-8", "mb-4")}>
        You've only answered <strong>{`${totalAnswers}`}</strong> out of{" "}
        <strong>{`${questions.length}`}</strong> question
        {questions.length === 1 ? "" : "s"}
      </p>
    );
  }

  return null;
}

interface SubmittedRoundSummaryProps {
  round: Round;
  playerRound: PlayerRound;
}

function SubmittedRoundSummary({
  round,
  playerRound,
}: SubmittedRoundSummaryProps) {
  return (
    <>
      <p className={c("text-3xl", "mb-10", "mt-4")}>
        {round.title} submitted{" "}
        <span className={c("text-success")}>
          <CheckBoxIcon
            color="inherit"
            fontSize="inherit"
            className={c("ml-4")}
          />
        </span>
      </p>

      <h2 className={c("text-2xl", "font-medium", "mb-6", "underline")}>
        Your answers
      </h2>
      <ol>
        {round.questions.map(({ questionNumber, answer, imageUrl }) => {
          const playerAnswer = playerRound.answers.find(
            (a) => a.questionNumber === questionNumber
          );
          const score = playerAnswer?.score || 0;
          return (
            <li
              key={`submitted-round-answer-${questionNumber}`}
              className={c("text-xl", "mb-16")}
            >
              {imageUrl ? (
                <div
                  className={c(
                    "w-full",
                    "flex",
                    "justify-center",
                    "items-center",
                    "mb-4"
                  )}
                >
                  <img
                    src={imageUrl}
                    alt={`Question number ${questionNumber}`}
                    className={c("w-full", "h-auto", "max-w-sm")}
                  />
                </div>
              ) : null}
              <div>
                Question {questionNumber}
                {playerRound.isMarked ? (
                  <>
                    {score > 0 ? (
                      <span className={c("text-success")}>
                        <CheckBoxIcon
                          color="inherit"
                          fontSize="inherit"
                          className={c("ml-2", "mb-1")}
                        />
                      </span>
                    ) : (
                      <span className={c("text-error")}>
                        <CancelIcon
                          color="inherit"
                          fontSize="inherit"
                          className={c("ml-2", "mb-1")}
                        />
                      </span>
                    )}
                    <br />
                    <span className={c("text-xl")}>
                      ({score} point{score === 1 ? "" : "s"})
                    </span>
                  </>
                ) : null}
              </div>
              <div>
                Your answer:{" "}
                <span
                  className={c("italic", "text-2xl", "whitespace-pre-line")}
                >
                  {playerAnswer?.answer || " - "}
                </span>
              </div>
            </li>
          );
        })}
      </ol>
    </>
  );
}

interface RoundProps {
  quizId: string;
  quizUrl: string;
  round: Round;
  player: Player;
}

export function RoundDetails({ quizId, quizUrl, round, player }: RoundProps) {
  const [showSubmitConfirmation, setShowSubmitConfirmation] = useState(false);
  const [submitError, setSubmitError] = useState("");
  const playerRound = player.rounds.find(
    ({ roundNumber }) => roundNumber === round.roundNumber
  );

  if (playerRound?.isSubmitted) {
    return <SubmittedRoundSummary round={round} playerRound={playerRound} />;
  }

  const initialAnswers: FormValues = {};
  round.questions.forEach((q) => {
    initialAnswers[`answer${q.questionNumber}`] =
      playerRound?.answers?.find((a) => a.questionNumber === q.questionNumber)
        ?.answer || "";
  });

  return (
    <>
      <h2 className={c("text-4xl", "my-4")}>{round.title}</h2>
      {round.description ? (
        <p className={c("text-2xl", "mb-16")}>{round.description}</p>
      ) : null}

      <Formik
        initialValues={initialAnswers}
        onSubmit={async (values) => {
          const answers: PlayerAnswer[] = round.questions.map(
            ({ questionNumber }) => ({
              questionNumber,
              answer: values[`answer${questionNumber}`],
            })
          );
          const otherRounds = player.rounds.filter(
            ({ roundNumber }) => roundNumber !== round.roundNumber
          );
          const thisRound: PlayerRound = {
            roundNumber: round.roundNumber,
            answers,
            isSubmitted: true,
            isMarked: false,
          };
          await updateQuizPlayer(quizId, player.id, {
            rounds: [...otherRounds, thisRound],
          });
          return;
        }}
      >
        {({ values, submitForm }) => (
          <Form>
            <ol
              className={c(
                "flex",
                "justify-center",
                "flex-wrap",
                "w-full",
                "items-end"
              )}
            >
              {round.questions.map(({ questionNumber, imageUrl }) => (
                <li
                  key={`round-question-${questionNumber}`}
                  className={c(
                    "mb-10",
                    "flex",
                    "flex-col",
                    "items-center",
                    "justify-between",
                    "w-full",
                    "mx-4",
                    "sm:w-500"
                  )}
                >
                  {imageUrl ? (
                    <div
                      className={c("w-full", "flex-1", "flex", "items-center")}
                    >
                      <img
                        src={imageUrl}
                        alt={`Question number ${questionNumber}`}
                        className={c("w-full", "h-auto", "mb-4")}
                      />
                    </div>
                  ) : null}
                  <TextArea
                    name={`answer${questionNumber}`}
                    label={`Question ${questionNumber}`}
                    id={`round-question-${questionNumber}`}
                    rows={2}
                  />
                </li>
              ))}
            </ol>
            <div
              className={c(
                "flex",
                "flex-col",
                "items-center",
                "border-dashed",
                "border-t",
                "border-white",
                "mt-8"
              )}
            >
              <Button
                onClick={() => setShowSubmitConfirmation(true)}
                className={c("mt-12")}
              >
                Submit answers
              </Button>

              <Link
                to={quizUrl}
                className={c(
                  "qb-a",
                  "mt-8",
                  "py-4",
                  "px-8",
                  "text-2xl",
                  "w-full",
                  "max-w-lg"
                )}
              >
                Back to other rounds
              </Link>

              <ModalConfirmation
                aria-labelledby="confirm-answer-sheet-submit-title"
                aria-describedby="confirm-answer-sheet-submit-description"
                open={showSubmitConfirmation}
                confirmText="Submit"
                onCancel={() => {
                  setSubmitError("");
                  setShowSubmitConfirmation(false);
                }}
                onConfirm={async () => {
                  setSubmitError("");
                  try {
                    await submitForm();
                    setShowSubmitConfirmation(false);
                  } catch {
                    setSubmitError(ERROR_MESSAGE.DEFAULT);
                  }
                }}
              >
                <div className={"text-center"}>
                  <h2
                    className={c("text-3xl", "mt-8")}
                    id="confirm-answer-sheet-submit-title"
                  >
                    Confirm submitting answer sheet
                  </h2>
                  <div id="confirm-answer-sheet-submit-description">
                    <AnsweredQuestionsSummary
                      values={values}
                      questions={round.questions}
                    />
                    <p className={c("text-2xl", "mt-8")}>
                      Are you sure you want to submit?
                    </p>
                  </div>
                  {submitError ? (
                    <Notification
                      className="mt-8"
                      state={NotificationState.ERROR}
                    >
                      {submitError}
                    </Notification>
                  ) : null}
                </div>
              </ModalConfirmation>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
