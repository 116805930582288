import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import c from "classnames";
import EditIcon from "@material-ui/icons/Edit";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";

import { ERROR_MESSAGE, EDIT_QUIZ_URL, HOST_QUIZ_URL } from "src/constants";
import {
  Quiz,
  Player,
  PLAYER_ADMISSION_STATUS,
  QUIZ_STATUS,
  startQuiz,
} from "src/services/dataService";
import { Button } from "src/components/Button";
import { Notification, NotificationState } from "src/components/Notification";
import { ModalConfirmation } from "src/components/Modal";
import { ManagePlayersButton } from "src/components/ManagePlayers";
import { DeleteQuizButton } from "src/components/DeleteQuizButton";
import { DuplicateQuizButton } from "src/components/DuplicateQuizButton";

interface StartQuizModalProps {
  quiz: Quiz;
  players: Player[];
  open: boolean;
  onClose: () => void;
}

function StartQuizModal({ quiz, players, open, onClose }: StartQuizModalProps) {
  const [error, setError] = useState("");
  const [processing, setProcessing] = useState(false);
  const totalApprovedPlayers = players.filter(
    (player) => player.admissionStatus === PLAYER_ADMISSION_STATUS.APPROVED
  ).length;
  const isPlural = totalApprovedPlayers !== 1;

  return (
    <ModalConfirmation
      cancelText="No"
      confirmText="Yes"
      onCancel={() => onClose()}
      onConfirm={async () => {
        setProcessing(true);
        try {
          await startQuiz(quiz.id);
          setProcessing(false);
          onClose();
        } catch {
          setError(ERROR_MESSAGE.DEFAULT);
          setProcessing(false);
        }
      }}
      processing={processing}
      open={open}
    >
      <>
        <p className={c("text-2xl", "text-center", "mt-4")}>
          You have {totalApprovedPlayers} confirmed player{isPlural ? "s" : ""}.
        </p>
        <p className={c("text-2xl", "text-center", "mt-4")}>
          Do you want to start the quiz?
        </p>
        {error ? (
          <Notification className={c("mt-4")} state={NotificationState.ERROR}>
            {error}
          </Notification>
        ) : null}
      </>
    </ModalConfirmation>
  );
}

interface QuizMasterActionsProps {
  quiz: Quiz;
  players: Player[];
}

export function QuizMasterActions({ quiz, players }: QuizMasterActionsProps) {
  const { status } = quiz;
  const history = useHistory();
  const [showStartQuiz, setShowStartQuiz] = useState(false);

  if (status === QUIZ_STATUS.STARTED) {
    return (
      <div className={c("p-8", "flex", "justify-center")}>
        <Link
          to={`${HOST_QUIZ_URL}/${quiz.id}`}
          className={c("qb-a", "w-full", "max-w-lg")}
        >
          Go To Quiz
        </Link>
      </div>
    );
  }

  if (status === QUIZ_STATUS.SCHEDULED) {
    return (
      <>
        <div className={c("p-8")}>
          <Button
            className={c("text-xl", "sm:text-2xl")}
            onClick={() => setShowStartQuiz(true)}
          >
            Start Quiz <ThumbUpIcon fontSize="inherit" className={c("ml-4")} />
          </Button>
          <Button
            className={c("mt-4", "text-xl", "sm:text-2xl")}
            onClick={() => {
              history.push(`${EDIT_QUIZ_URL}/${quiz.id}`);
            }}
          >
            Edit Quiz <EditIcon fontSize="inherit" className={c("ml-4")} />
          </Button>
          <ManagePlayersButton quiz={quiz} players={players} />
          <DeleteQuizButton quiz={quiz} className={c("mt-4")} />
        </div>

        <StartQuizModal
          quiz={quiz}
          players={players}
          open={showStartQuiz}
          onClose={() => setShowStartQuiz(false)}
        />
      </>
    );
  }

  if (status === QUIZ_STATUS.COMPLETED) {
    return (
      <div className={c("p-8", "flex", "flex-col", "items-center")}>
        <Link
          to={`${HOST_QUIZ_URL}/${quiz.id}`}
          className={c("qb-a", "w-full", "max-w-lg")}
        >
          View Quiz
        </Link>
        <DuplicateQuizButton quiz={quiz} className={c("mt-4")} />
      </div>
    );
  }

  return null;
}
