import React, {
  InputHTMLAttributes,
  LabelHTMLAttributes,
  ReactNode,
  TextareaHTMLAttributes,
  PropsWithChildren,
  CSSProperties,
} from "react";
import { useField, ErrorMessage } from "formik";
import c from "classnames";

export interface InputWrapperProps {
  id: string;
  label: ReactNode;
  name: string;
  extra?: ReactNode;
  labelProps?: LabelHTMLAttributes<HTMLLabelElement>;
  className?: string;
  style?: CSSProperties;
}

function InputWrapper({
  id,
  name,
  label,
  labelProps,
  style,
  className,
  extra = null,
  children,
}: PropsWithChildren<InputWrapperProps>) {
  const [field, meta] = useField(name);

  return (
    <div className={c("w-full", "text-left", className)} style={style}>
      <div className={c("w-full", "flex", "justify-start", "items-center")}>
        <label
          className={c("text-xl", "flex-1", {
            "text-error": meta.error && meta.touched,
          })}
          htmlFor={id}
          {...labelProps}
        >
          {label}:
        </label>
        {extra ? <div>{extra}</div> : null}
      </div>
      {children}
      <ErrorMessage
        name={field.name}
        component="div"
        className={c("text-xl", "text-error", "font-medium")}
      />
    </div>
  );
}

export interface InputProps
  extends InputHTMLAttributes<HTMLInputElement>,
    InputWrapperProps {
  id: string;
  name: string;
  value?: any;
}

export function Input({
  id,
  name,
  label,
  labelProps,
  style,
  className,
  extra,
  ...props
}: InputProps) {
  const [field, meta] = useField(name);

  return (
    <InputWrapper
      id={id}
      name={name}
      label={label}
      labelProps={labelProps}
      style={style}
      className={className}
      extra={extra}
    >
      <input
        autoComplete="off"
        className={c(
          "text-xl",
          "p-3",
          "border-solid",
          "border-2",
          "border-transparent",
          "rounded",
          "text-secondary",
          "w-full",
          {
            "border-error": meta.error && meta.touched,
          }
        )}
        id={id}
        {...field}
        {...props}
      />
    </InputWrapper>
  );
}

export interface TextAreaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement>,
    InputWrapperProps {
  id: string;
  name: string;
}

export function TextArea({
  id,
  name,
  label,
  labelProps,
  style,
  className,
  extra,
  ...props
}: TextAreaProps) {
  const [field, meta] = useField(name);

  return (
    <InputWrapper
      id={id}
      name={name}
      label={label}
      labelProps={labelProps}
      style={style}
      className={className}
      extra={extra}
    >
      <textarea
        autoComplete="off"
        className={c(
          "text-xl",
          "p-3",
          "border-solid",
          "border-2",
          "border-transparent",
          "rounded",
          "text-secondary",
          "w-full",
          {
            "border-error": meta.error && meta.touched,
          }
        )}
        id={id}
        {...field}
        {...props}
      />
    </InputWrapper>
  );
}
