import React, { useState, useEffect } from "react";
import c from "classnames";

import { ModalConfirmation, ModalProps } from "src/components/Modal";
import { Notification, NotificationState } from "src/components/Notification";
import { ERROR_MESSAGE } from "src/constants";
import { Round, updateQuiz } from "src/services/dataService";

interface DeleteRoundModalProps extends Omit<ModalProps, "children"> {
  onCancel: () => void;
  onConfirm: (rounds: Round[]) => void;
  rounds: Round[];
  quizId: string;
  roundNumber: number | null;
}

export function DeleteRoundModal({
  open,
  onCancel,
  onConfirm,
  rounds,
  roundNumber,
  quizId,
}: DeleteRoundModalProps) {
  const [error, setError] = useState(false);
  const deletingRound = rounds.find((r) => r.roundNumber === roundNumber);
  const otherRounds = rounds
    .filter((r) => r.roundNumber !== roundNumber)
    .map((round, index) => ({
      ...round,
      roundNumber: index + 1,
    }));

  useEffect(() => {
    return function () {
      setError(false);
    };
  }, []);

  return (
    <ModalConfirmation
      aria-labelledby="delete-round-modal-title"
      aria-describedby="delete-round-modal-description"
      open={open}
      onCancel={onCancel}
      onConfirm={() => {
        setError(false);
        updateQuiz(quizId, { rounds: otherRounds })
          .then(() => {
            onConfirm(otherRounds);
          })
          .catch(() => {
            setError(true);
          });
      }}
    >
      <div className={c("w-full", "flex", "flex-col", "items-center")}>
        <h2 id="delete-round-modal-title" className={c("text-3xl")}>
          Delete Round
        </h2>
        <p id="delete-round-modal-description" className={c("text-xl", "mt-8")}>
          Are you sure you want to delete round "{deletingRound?.title ?? ""}"?
        </p>
        {error ? (
          <Notification className="mt-8" state={NotificationState.ERROR}>
            {ERROR_MESSAGE.DEFAULT}
          </Notification>
        ) : null}
      </div>
    </ModalConfirmation>
  );
}
