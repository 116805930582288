import * as firebase from "firebase/app";

export const UPLOAD_STATE_CHANGED = firebase.storage.TaskEvent.STATE_CHANGED;
export const UPLOAD_PAUSED = firebase.storage.TaskState.PAUSED;
export const UPLOAD_RUNNING = firebase.storage.TaskState.RUNNING;

interface QuestionImageUpload {
  file: File;
  metadata: firebase.storage.UploadMetadata;
  suffix: string;
  uid: string;
  quizId: string;
  roundNumber: number;
  questionNumber: number;
}

export function uploadQuestionImage({
  file,
  metadata,
  suffix,
  uid,
  quizId,
  roundNumber,
  questionNumber,
}: QuestionImageUpload) {
  const storageRef = firebase.storage().ref();
  const randomString = Math.random().toString(36).substring(2);
  return storageRef
    .child(
      `${uid}/${quizId}/images/r-${roundNumber}-q-${questionNumber}-${randomString}${suffix}`
    )
    .put(file, metadata);
}
