import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams, Redirect } from "react-router-dom";
import { Formik, Form, ErrorMessage } from "formik";
import c from "classnames";
import dayjs from "dayjs";
import { Fab } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { useTheme } from "@material-ui/core/styles";

import { Input } from "src/components/Input";
import { Modal } from "src/components/Modal";
import { HOME_URL, ERROR_MESSAGE } from "src/constants";
import { quizValidation } from "src/validationSchemas";
import { SubmitButton, Button } from "src/components/Button";
import { Notification, NotificationState } from "src/components/Notification";
import { DateInput } from "src/components/DateInput";
import { LoadingPage } from "src/components/Loading";
import { PageLayout } from "src/components/PageLayout";
import {
  subscribeQuizData,
  Quiz,
  Round,
  updateQuiz,
  deleteQuiz,
  QUIZ_STATUS,
} from "src/services/dataService";
import { AuthContext } from "src/services/authService";
import { Pluralise } from "src/components/Pluralise";

import { DeleteRoundModal } from "./DeleteRoundModal";
import { EditRoundForm } from "./EditRoundForm";

interface EditRoundState {
  showModal: boolean;
  roundNumber: number | null;
}

const initialEditRoundState: EditRoundState = {
  showModal: false,
  roundNumber: null,
};

interface EditQuizProps {
  isCreateQuiz?: boolean;
}

export function EditQuiz({ isCreateQuiz = false }: EditQuizProps) {
  const history = useHistory();
  const theme = useTheme();
  const { quizId } = useParams();
  const { user } = useContext(AuthContext);
  const [createNewRound, setCreateNewRound] = useState(false);
  const [editRoundState, setEditRoundState] = useState<EditRoundState>(
    initialEditRoundState
  );
  const [deleteRoundState, setDeleteRoundState] = useState<EditRoundState>(
    initialEditRoundState
  );
  const [submitError, setSubmitError] = useState("");
  const [dataError, setDataError] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [quiz, setQuiz] = useState<Quiz | null>(null);
  const pageHeading = isCreateQuiz ? "Create Quiz" : "Edit Quiz";

  useEffect(() => {
    let unsubscribe = () => {};
    if (quizId) {
      unsubscribe = subscribeQuizData(
        quizId,
        (subscribedQuiz) => {
          setQuiz(subscribedQuiz);
        },
        () => {
          setDataError("Error fetching quiz data");
        }
      );
    }

    return function () {
      unsubscribe();
    };
  }, [quizId]);

  if (!quizId) {
    return <Redirect to={HOME_URL} />;
  }

  if (dataError) {
    return <PageLayout heading={pageHeading}>{dataError}</PageLayout>;
  }

  if (!quiz) {
    return (
      <PageLayout heading={pageHeading}>
        <LoadingPage />
      </PageLayout>
    );
  }

  return (
    <PageLayout
      heading={pageHeading}
      className={c("w-4/5", "max-w-md", "py-12")}
    >
      <Formik
        initialValues={{
          title: quiz.title || "",
          scheduledStart:
            quiz.scheduledStartUnix &&
            dayjs.unix(quiz.scheduledStartUnix).isValid()
              ? dayjs.unix(quiz.scheduledStartUnix)
              : dayjs().add(1, "day").hour(19).minute(0),
          rounds: quiz.rounds || [],
        }}
        validationSchema={quizValidation}
        onSubmit={async ({ title, scheduledStart }) => {
          setSubmitError("");
          try {
            await updateQuiz(quizId, {
              title,
              status: QUIZ_STATUS.SCHEDULED,
              scheduledStartUnix: dayjs(scheduledStart).unix(),
            });
            history.push(HOME_URL);
          } catch {
            setSubmitError(ERROR_MESSAGE.DEFAULT);
          }
        }}
      >
        {({ setFieldValue }) => {
          return (
            <Form>
              <Input name="title" label="Quiz title" id="quiz-title" />
              <DateInput
                name="scheduledStart"
                label="Scheduled Start Time"
                id="quiz-start"
                className="mt-4"
              />
              <section>
                <div
                  className={c(
                    "flex",
                    "w-full",
                    "items-center",
                    "justify-between",
                    "mt-10",
                    "border-b",
                    "border-solid",
                    "border-white",
                    "pb-2"
                  )}
                >
                  <h2 className={c("text-2xl")}>Rounds</h2>
                  <Fab
                    color="primary"
                    size="medium"
                    aria-label="Add round"
                    onClick={() => setCreateNewRound(true)}
                  >
                    <AddIcon />
                  </Fab>
                </div>
                {quiz?.rounds?.length && quiz.rounds.length > 0 ? (
                  <ol>
                    {quiz.rounds.map(
                      ({ title, questions, roundNumber }, index) => (
                        <li
                          key={`edit-quiz-round-${index}`}
                          className={c("mt-10", "flex", "text-2xl", "w-full")}
                        >
                          <div className={c("mr-4")}>{roundNumber}.</div>
                          <div className={c("flex", "flex-col", "w-full")}>
                            <div className={c("text-left", "mb-1")}>
                              {title}
                            </div>
                            <div
                              className={c(
                                "flex",
                                "justify-start",
                                "items-center"
                              )}
                            >
                              <Pluralise
                                singular="question"
                                count={questions.length}
                                className={c(
                                  "text-lg",
                                  "text-gray-400",
                                  "whitespace-no-wrap"
                                )}
                              />
                              <div
                                className={c("ml-4", "flex", "justify-start")}
                              >
                                <Fab
                                  color="primary"
                                  size="small"
                                  aria-label="Edit round"
                                  onClick={() =>
                                    setEditRoundState({
                                      showModal: true,
                                      roundNumber,
                                    })
                                  }
                                >
                                  <EditIcon />
                                </Fab>
                                <Fab
                                  style={{
                                    backgroundColor: theme.palette.error.main,
                                    marginLeft: 10,
                                  }}
                                  size="small"
                                  aria-label="Delete round"
                                  onClick={() =>
                                    setDeleteRoundState({
                                      showModal: true,
                                      roundNumber,
                                    })
                                  }
                                >
                                  <DeleteForeverIcon />
                                </Fab>
                              </div>
                            </div>
                          </div>
                        </li>
                      )
                    )}
                  </ol>
                ) : (
                  <div className={c("text-2xl", "p-8")}>No Rounds added</div>
                )}
              </section>

              <ErrorMessage name="rounds">
                {(errorMsg) => (
                  <div className={c("text-2xl", "text-error", "font-medium")}>
                    {errorMsg}
                  </div>
                )}
              </ErrorMessage>

              <Modal
                open={createNewRound}
                onClose={() => setCreateNewRound(false)}
                aria-labelledby="create-round-modal-title"
              >
                <div className={c("w-full")}>
                  <h2
                    id="create-round-modal-title"
                    className={c("text-3xl", "w-full", "p-4", "text-center")}
                  >
                    Add Round
                  </h2>
                  <EditRoundForm
                    quizId={quizId}
                    rounds={quiz.rounds || []}
                    user={user!}
                    onUpdated={(rounds: Round[]) => {
                      setFieldValue("rounds", rounds);
                      setCreateNewRound(false);
                    }}
                    onCancel={() => setCreateNewRound(false)}
                  />
                </div>
              </Modal>

              <Modal
                open={editRoundState.showModal}
                onClose={() => setEditRoundState(initialEditRoundState)}
                aria-labelledby="edit-round-modal-title"
              >
                <div className={c("w-full")}>
                  <h2
                    id="edit-round-modal-title"
                    className={c("text-3xl", "w-full", "p-4", "text-center")}
                  >
                    Edit Round
                  </h2>
                  <EditRoundForm
                    editState={{
                      isEdit: editRoundState.showModal,
                      roundNumber: editRoundState.roundNumber,
                    }}
                    quizId={quizId}
                    rounds={quiz.rounds || []}
                    user={user!}
                    onUpdated={(rounds: Round[]) => {
                      setFieldValue("rounds", rounds);
                      setEditRoundState(initialEditRoundState);
                    }}
                    onCancel={() => setEditRoundState(initialEditRoundState)}
                  />
                </div>
              </Modal>

              <DeleteRoundModal
                open={deleteRoundState.showModal}
                onCancel={() => setDeleteRoundState(initialEditRoundState)}
                onConfirm={(rounds: Round[]) => {
                  setFieldValue("rounds", rounds);
                  setDeleteRoundState(initialEditRoundState);
                }}
                quizId={quizId}
                rounds={quiz.rounds || []}
                roundNumber={deleteRoundState.roundNumber}
              />

              {submitError ? (
                <Notification className="mt-12" state={NotificationState.ERROR}>
                  {submitError}
                </Notification>
              ) : null}

              <SubmitButton className="mt-12">Save</SubmitButton>

              {isCreateQuiz ? (
                <Button
                  className={c("qb-btn-secondary", "mt-4")}
                  onClick={async () => {
                    setSubmitError("");
                    setIsDeleting(true);
                    try {
                      await deleteQuiz(quizId);
                      history.push(HOME_URL);
                    } catch {
                      setSubmitError(ERROR_MESSAGE.DEFAULT);
                      setIsDeleting(false);
                    }
                  }}
                  loading={isDeleting}
                >
                  Discard
                </Button>
              ) : (
                <Button
                  className={c("qb-btn-secondary", "mt-4")}
                  onClick={() => {
                    history.push(HOME_URL);
                  }}
                >
                  Cancel
                </Button>
              )}
            </Form>
          );
        }}
      </Formik>
    </PageLayout>
  );
}
