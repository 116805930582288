import React, { CSSProperties } from "react";

interface PluraliseProps {
  count: number;
  singular: string;
  plural?: string;
  style?: CSSProperties;
  className?: string;
}

export function Pluralise({
  singular,
  count,
  plural,
  style,
  className,
}: PluraliseProps) {
  plural = plural || `${singular}s`;
  return (
    <span style={style} className={className}>
      {`${count}`} {count === 1 ? `${singular}` : `${plural}`}
    </span>
  );
}
