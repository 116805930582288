import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import c from "classnames";
import { ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DayJsUtils from "@date-io/dayjs";

import { muiTheme } from "src/theme";
import { AuthProvider } from "src/services/authService";
import { ErrorBoundary } from "src/components/ErrorBoundary";
import { Routes } from "src/routes";

export function App() {
  return (
    <div
      className={c(
        "text-center",
        "mt-4",
        "mx-4",
        "mb-24",
        "flex",
        "flex-col",
        "items-center"
      )}
      style={{ width: "calc(100% - 2rem)" }}
    >
      <ErrorBoundary>
        <ThemeProvider theme={muiTheme}>
          <MuiPickersUtilsProvider utils={DayJsUtils}>
            <AuthProvider>
              <Router>
                <Routes />
              </Router>
            </AuthProvider>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </ErrorBoundary>
    </div>
  );
}
