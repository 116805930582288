import React, { PropsWithChildren, ReactNode, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import c from "classnames";

import { HOME_URL } from "src/constants";

function HomeLink() {
  return (
    <Link to={HOME_URL} className={c("qb-a", "w-full")}>
      Home
    </Link>
  );
}

interface PageLayoutProps {
  className?: string;
  heading?: ReactNode;
  action?: ReactNode;
}

export function PageLayout({
  heading = "Quiz Bits",
  className,
  action = <HomeLink />,
  children,
}: PropsWithChildren<PageLayoutProps>) {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <header
        className={c(
          "flex",
          "flex-col",
          "items-center",
          "self-stretch",
          "justify-center",
          "relative",
          "border-b",
          "border-dashed",
          "border-white",
          "mb-8"
        )}
      >
        <h1
          className={c(
            "text-5xl",
            "my-4",
            "font-bold",
            "leading-tight",
            "w-full",
            "md:px-24"
          )}
        >
          {heading}
        </h1>
        <div
          className={c(
            "relative",
            "h-full",
            "w-full",
            "flex",
            "items-start",
            "top-0",
            "left-0",
            "mb-4",
            "md:absolute",
            "md:pl-4",
            "md:pt-5",
            "md:mb-0",
            "md:w-auto"
          )}
        >
          {action}
        </div>
      </header>
      <div className={className}>{children}</div>
    </>
  );
}
