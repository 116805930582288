import React, { useState } from "react";
import c from "classnames";
import { useField, ErrorMessage } from "formik";
import { Loading } from "./Loading";
import {
  uploadQuestionImage,
  UPLOAD_STATE_CHANGED,
} from "src/services/storageService";
import { User } from "firebase";

const FILE_TYPE_SUFFIX_MAP: { [key: string]: string } = {
  "image/jpeg": ".jpg",
  "image/png": ".png",
  "image/svg+xml": ".svg",
};

const MAX_FILE_SIZE_MB = 5;

interface FileUploadProps {
  name: string;
  user: User;
  quizId: string;
  roundNumber: number;
  questionNumber: number;
}

export function QuestionImageUpload({
  name,
  user,
  quizId,
  roundNumber,
  questionNumber,
}: FileUploadProps) {
  const [field, meta, helpers] = useField(name);
  const [uploading, setUploading] = useState(false);

  function setError(msg?: string) {
    msg = msg || "Looks like something went wrong, try uploading again";
    helpers.setError(msg);
    helpers.setTouched(true);
  }

  function handleImageUplaod(file: File | null | undefined) {
    helpers.setError(false);

    if (file) {
      const fileSizeMb = file.size / 1024 / 1024;

      if (!Object.keys(FILE_TYPE_SUFFIX_MAP).includes(file.type)) {
        setError(`Unregonised image file type ${file.type}`);
        return;
      }

      if (fileSizeMb > MAX_FILE_SIZE_MB) {
        setError(
          `File size ${fileSizeMb.toFixed(
            1
          )}MB is too big, maximum allowed is ${MAX_FILE_SIZE_MB}MB`
        );
        return;
      }

      try {
        setUploading(true);

        const uploadTask = uploadQuestionImage({
          file,
          metadata: {
            contentType: file.type,
          },
          suffix: FILE_TYPE_SUFFIX_MAP[file.type],
          uid: user!.uid,
          quizId,
          roundNumber,
          questionNumber,
        });

        uploadTask.on(
          UPLOAD_STATE_CHANGED,
          function (snapshot) {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
          },
          function (error) {
            console.log("Upload error ", error);
            setError();
            setUploading(false);
          },
          function () {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              console.log("File available at", downloadURL);
              helpers.setValue(downloadURL);
              helpers.setTouched(true);
              setUploading(false);
            });
          }
        );
      } catch {
        setError();
        setUploading(false);
      }
    }
  }

  return (
    <div className={c("w-full", "mt-4")}>
      <div
        className={c("w-full", "relative", "flex", "flex-col", "lg:flex-row")}
      >
        <input
          type="file"
          id="testy"
          disabled={uploading}
          accept="image/*"
          className={c(
            "border-white",
            "border",
            "border-dashed",
            "rounded",
            "py-16",
            "px-8",
            "w-full",
            "mr-0",
            "text-center",
            "lg:w-1/2",
            "lg:mr-2",
            {
              "border-success": !!field.value,
              "border-error": !!meta.touched && !!meta.error,
            }
          )}
          onChange={(event) => {
            console.log("change event: ", event.target.files);
            handleImageUplaod(event.target.files?.item(0));
          }}
        />
        {field.value ? (
          <img
            src={field.value}
            alt={`Question ${questionNumber}`}
            className={c(
              "w-full",
              "border-white",
              "border",
              "border-solid",
              "rounded",
              "ml-0",
              "mt-4",
              "lg:w-1/2",
              "lg:ml-2",
              "lg:mt-0",
              {
                "border-success": !!field.value,
                "border-error": !!meta.touched && !!meta.error,
              }
            )}
          />
        ) : (
          <div
            className={c(
              "w-full",
              "border-white",
              "border",
              "border-solid",
              "rounded",
              "ml-0",
              "mt-4",
              "py-16",
              "flex",
              "items-center",
              "justify-center",
              "lg:w-1/2",
              "lg:ml-2",
              "lg:mt-0",
              {
                "border-error": !!meta.touched && !!meta.error,
              }
            )}
          >
            No image uploaded
          </div>
        )}
        {uploading ? (
          <div
            className={c(
              "absolute",
              "w-full",
              "h-full",
              "flex",
              "justify-center",
              "items-center",
              "text-primary",
              "bg-secondary-opacity75"
            )}
          >
            <Loading size={40} />
          </div>
        ) : null}
      </div>
      <ErrorMessage name={name}>
        {(msg) => (
          <div className={c("text-error", "text-right", "mt-4")}>{msg}</div>
        )}
      </ErrorMessage>
    </div>
  );
}
