import React, { useContext } from "react";
import { Link } from "react-router-dom";
import c from "classnames";
import { User } from "firebase";

import { LOGIN_URL, CREATE_ACCOUNT_URL, MY_QUIZZES_URL } from "src/constants";
import { AuthContext, signOut } from "src/services/authService";
import { QUIZ_STATUS } from "src/services/dataService";
import { QuizMasterContent } from "src/components/QuizMasterContent";
import { CreateQuizButton } from "src/components/CreateQuizButton";
import { QuizListByStatus } from "src/components/QuizList";
import { PageLayout } from "src/components/PageLayout";

function WelcomeMessage({ user }: { user: User | null }) {
  return (
    <>
      {user ? (
        <>
          <p>
            Welcome{" "}
            {user.displayName ?? (
              <span role="img" aria-label="Person Shrugging Emoji">
                🤷
              </span>
            )}
            !
            <span
              role="img"
              aria-label="Party Popper Emoji"
              className={c("ml-4", "text-4xl")}
            >
              🎉
            </span>
          </p>
          <p className="text-sm">
            not you?{" "}
            <button
              className={c(
                "text-primary",
                "underline",
                "hover:text-primary-dark"
              )}
              onClick={() => signOut()}
            >
              sign out
            </button>
          </p>
        </>
      ) : (
        <p>
          Welcome!
          <span role="img" aria-label="Party Popper Emoji" className="ml-4">
            🎉
          </span>
        </p>
      )}
    </>
  );
}

export function HomePage() {
  const { user } = useContext(AuthContext);

  return (
    <PageLayout
      heading="Quiz Bits"
      action={null}
      className={c(
        "text-center",
        "bg-secondary",
        "flex",
        "flex-col",
        "items-center",
        "justify-center",
        "text-3xl",
        "pb-20",
        "w-full"
      )}
    >
      <div
        className={c("w-full", "max-w-2xl", "flex", "flex-col", "items-center")}
      >
        <WelcomeMessage user={user} />

        {user ? (
          <>
            <QuizMasterContent
              fallback={
                <p className={c("text-2xl", "mt-10")}>
                  Join a quiz from the list below.
                </p>
              }
            >
              <p className={c("text-2xl", "my-10")}>
                Join a quiz from the list below, or create a new one.
              </p>
              <CreateQuizButton />
              <Link
                to={MY_QUIZZES_URL}
                className={c(
                  "qb-a",
                  "text-xl",
                  "max-w-lg",
                  "w-full",
                  "sm:text-2xl",
                  "sm:w-1/2"
                )}
              >
                Go to My Quizzes
              </Link>
            </QuizMasterContent>
            <h2 className="mt-10">Upcoming Quizzes</h2>
            <QuizListByStatus
              quizStatuses={[QUIZ_STATUS.SCHEDULED, QUIZ_STATUS.STARTED]}
            />
          </>
        ) : (
          <>
            <p className={c("text-2xl", "my-10")}>Sign in to start quizzing!</p>
            <Link
              to={LOGIN_URL}
              className="qb-a mt-8"
              style={{ minWidth: "17rem" }}
            >
              Login
            </Link>
            <div className="text-2xl italic my-4">or</div>
            <Link
              to={CREATE_ACCOUNT_URL}
              className="qb-a"
              style={{ minWidth: "17rem" }}
            >
              Create an account
            </Link>
          </>
        )}
      </div>
    </PageLayout>
  );
}
