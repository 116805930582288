import React, { useEffect, useState } from "react";
import c from "classnames";

import {
  subscribeQuizzesByStatus,
  QUIZ_STATUS,
  Quiz,
  subscribeUserQuizzesByStatus,
} from "src/services/dataService";
import { QuizSummaryCard } from "src/components/QuizSummaryCard";
import { Loading } from "src/components/Loading";

interface QuizListProps {
  quizStatuses: QUIZ_STATUS[];
  uid?: string;
}

function QuizList({ uid, quizStatuses }: QuizListProps) {
  const [quizzes, setQuizzes] = useState<Quiz[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    let unsubscribe = () => {};
    const onNext = (subscribedQuizzes: Quiz[]) => {
      setQuizzes(subscribedQuizzes);
      setLoading(false);
    };
    const onError = () => {
      setError(true);
      setLoading(false);
    };

    if (uid) {
      unsubscribe = subscribeUserQuizzesByStatus(
        uid,
        quizStatuses,
        onNext,
        onError
      );
    } else {
      unsubscribe = subscribeQuizzesByStatus(quizStatuses, onNext, onError);
    }

    return function () {
      unsubscribe();
    };
  }, [quizStatuses, uid]);

  if (loading) {
    return (
      <div className="p-12">
        <Loading size={30} />
      </div>
    );
  }

  if (error) {
    return (
      <p className={c("my-8", "text-2xl")}>Error fetching scheduled quizzes</p>
    );
  }

  return (
    <>
      {quizzes.length > 0 ? (
        <ol className={c("w-full")}>
          {quizzes.map((quiz, index) => (
            <li key={`quiz-list-item-${index}`} className={c("w-full")}>
              <QuizSummaryCard quiz={quiz} />
            </li>
          ))}
        </ol>
      ) : (
        <>
          <p className={c("my-8", "text-2xl")}>
            No quizzes found I'm afraid...
            <span
              role="img"
              aria-label="Disappointed Face Emoji"
              className={c("text-3xl", "ml-1")}
            >
              😞
            </span>
          </p>

          <div className={c("w-full", "flex", "justify-center")}>
            <img
              src="https://media.giphy.com/media/FzV61A4U3guAw/giphy.gif"
              alt="Jaws gif"
              className={c("pointer-events-none", "m-4")}
              style={{ height: "40vmin" }}
            />
          </div>
        </>
      )}
    </>
  );
}

interface QuizListByStatus {
  quizStatuses: QUIZ_STATUS[];
}

export function QuizListByStatus({ quizStatuses }: QuizListByStatus) {
  return <QuizList quizStatuses={quizStatuses} />;
}

interface QuizListByUserAndStatusProps {
  quizStatuses: QUIZ_STATUS[];
  uid: string;
}

export function QuizListByUserAndStatus({
  uid,
  quizStatuses,
}: QuizListByUserAndStatusProps) {
  if (uid) {
    return <QuizList uid={uid} quizStatuses={quizStatuses} />;
  }

  return null;
}
