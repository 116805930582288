import * as firebase from "firebase/app";

type DocumentData = firebase.firestore.DocumentData;
type DocumentSnapshot = firebase.firestore.DocumentSnapshot<DocumentData>;

export enum PLAYER_ADMISSION_STATUS {
  PENDING = "pending",
  REJECTED = "rejected",
  APPROVED = "approved",
}

export interface PlayerAnswer {
  questionNumber: number;
  answer: string;
  score?: number;
}

export interface PlayerRound {
  roundNumber: number;
  answers: PlayerAnswer[];
  isSubmitted: boolean;
  isMarked: boolean;
}

export interface Player {
  id: string;
  rounds: PlayerRound[];
  teamName: string;
  admissionStatus: PLAYER_ADMISSION_STATUS;
}

function convertSnapshotToPlayer(snapshot: DocumentSnapshot): Player {
  return {
    id: snapshot.id,
    ...snapshot.data(),
  } as Player;
}

export function addPendingPlayer(
  uid: string,
  teamName: string,
  quizId: string
) {
  return firebase
    .firestore()
    .collection("quizzes")
    .doc(quizId)
    .collection("players")
    .doc(uid)
    .set({
      rounds: [],
      teamName,
      admissionStatus: PLAYER_ADMISSION_STATUS.PENDING,
    });
}

export function updateQuizPlayer(
  quizId: string,
  playerId: string,
  playerData: firebase.firestore.UpdateData
) {
  return firebase
    .firestore()
    .collection("quizzes")
    .doc(quizId)
    .collection("players")
    .doc(playerId)
    .update(playerData);
}

export function updateQuizPlayerAdmissionStatuses(
  quizId: string,
  currentPlayers: Player[],
  newPlayers: Player[]
) {
  const updatedPlayers = newPlayers.filter((newPlayer) => {
    const currentPlayer = currentPlayers.find((p) => p.id === newPlayer.id);
    if (
      currentPlayer &&
      currentPlayer.admissionStatus !== newPlayer.admissionStatus
    ) {
      return true;
    }
    return false;
  });

  return Promise.all(
    updatedPlayers.map(({ id, admissionStatus }) =>
      updateQuizPlayer(quizId, id, { admissionStatus })
    )
  );
}

export function subscribeQuizPlayers(
  quizId: string,
  onNext: (players: Player[]) => void,
  onError?: (error: Error) => void
) {
  return firebase
    .firestore()
    .collection("quizzes")
    .doc(quizId)
    .collection("players")
    .onSnapshot(async (snapshot) => {
      const players: Player[] = [];
      snapshot.forEach((doc) => {
        players.push(convertSnapshotToPlayer(doc));
      });
      onNext(players);
    }, onError);
}
