import React, { useState } from "react";
import c from "classnames";
import { User } from "firebase";
import { Formik, Form } from "formik";

import { ERROR_MESSAGE } from "src/constants";
import { joinQuizValidation } from "src/validationSchemas";
import { Quiz, addPendingPlayer } from "src/services/dataService";

import { Input } from "src/components/Input";
import { SubmitButton } from "src/components/Button";
import { Notification, NotificationState } from "src/components/Notification";

interface JoinQuizFormProps {
  quiz: Quiz;
  user: User;
}

export function JoinQuizForm({ quiz, user }: JoinQuizFormProps) {
  const [error, setError] = useState("");

  return (
    <Formik
      initialValues={{ teamName: "" }}
      validationSchema={joinQuizValidation}
      onSubmit={async ({ teamName }) => {
        setError("");
        try {
          await addPendingPlayer(user.uid, teamName, quiz.id);
        } catch {
          setError(ERROR_MESSAGE.DEFAULT);
        }
      }}
    >
      {() => (
        <Form>
          <Input
            id="join-quiz-form-team-name-input"
            name="teamName"
            label="Team Name"
            autoComplete="on"
          />
          {error ? (
            <Notification className={c("mt-4")} state={NotificationState.ERROR}>
              {error}
            </Notification>
          ) : null}
          <SubmitButton className={c("mt-4")}>Request to join</SubmitButton>
        </Form>
      )}
    </Formik>
  );
}
