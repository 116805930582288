module.exports = {
  theme: {
    extend: {
      colors: {
        primary: {
          dark: "#0ea3cb",
          default: "#61dafb",
        },
        secondary: {
          light: "#004756",
          dark: "#1e2023",
          default: "#282c34",
          opacity75: "#282c34bf",
        },
        error: {
          default: "#f35757",
          dark: "#a93030",
        },
        success: "#04b904",
      },
      minWidth: {
        body: "300px",
      },
      width: {
        "500": "500px",
      },
    },
    boxShadow: {
      white:
        "0 10px 15px -3px rgba(255, 255, 255, 0.4), 0 4px 6px -2px rgba(255, 255, 255, 0.1);",
      primary:
        "0 10px 15px -3px rgba(97, 218, 251, 0.4), 0 4px 6px -2px rgba(97, 218, 251, 0.1);",
      success:
        "0 10px 15px -3px rgba(4, 185, 4, 0.4), 0 4px 6px -2px rgba(4, 185, 4, 0.1);",
      error:
        "0 10px 15px -3px rgba(243, 87, 87, 0.4), 0 4px 6px -2px rgba(243, 87, 87, 0.1);",
    },
  },
  variants: {},
  plugins: [],
};
