import React, {
  useState,
  useEffect,
  createContext,
  PropsWithChildren,
} from "react";
import * as firebase from "firebase/app";
import { User } from "firebase";

import { LoadingPage } from "src/components/Loading";
import { subscribeUserData } from "src/services/dataService";

interface AuthContextState {
  user: User | null;
  isQuizMaster: boolean;
}

const initialState = { user: null, isQuizMaster: false };

export const AuthContext = createContext<AuthContextState>(initialState);

export function AuthProvider({ children }: PropsWithChildren<{}>) {
  const [authState, setAuthState] = useState<AuthContextState>(initialState);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let unsubscribeData = () => {};
    const unsubscribeAuth = firebase.auth().onAuthStateChanged(
      (user) => {
        if (user) {
          setAuthState((state) => ({ ...state, user }));
          unsubscribeData = subscribeUserData(
            user.uid,
            (snapshot) => {
              setAuthState((state) => ({
                ...state,
                isQuizMaster: snapshot?.data()?.isQuizMaster,
              }));
              setLoading(false);
            },
            () => {
              setLoading(false);
            }
          );
        } else {
          unsubscribeData();
          setAuthState(initialState);
          setLoading(false);
        }
      },
      () => {
        setLoading(false);
      }
    );

    return function () {
      unsubscribeAuth();
      unsubscribeData();
    };
  }, []);

  if (loading) {
    return <LoadingPage />
  }

  return (
    <AuthContext.Provider value={authState}>{children}</AuthContext.Provider>
  );
}

export function createUser(email: string, password: string) {
  return firebase.auth().createUserWithEmailAndPassword(email, password);
}

export function signIn(email: string, password: string) {
  return firebase.auth().signInWithEmailAndPassword(email, password);
}

export function signOut() {
  return firebase.auth().signOut();
}

export function sendPasswordReset(email: string) {
  return firebase.auth().sendPasswordResetEmail(email)
}
