import React, { useState, useEffect } from "react";
import c from "classnames";

import { getUserData } from "src/services/dataService";
import { Loading } from "src/components/Loading";

interface UserDisplayNameProps {
  uid: string;
}

export function UserDisplayName({ uid }: UserDisplayNameProps) {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getUserData(uid)
      .then((snapShot) => {
        setName(snapShot.data()?.displayName);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [uid]);

  if (loading) {
    return <Loading className={c("ml-4")} size={20} />;
  }

  return <span>{name ?? "unknown"}</span>;
}
