import React, { useContext, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { Formik, Form } from "formik";
import c from "classnames";

import { Input } from "src/components/Input";
import { SignOut } from "src/components/SignOut";
import { HOME_URL, LOGIN_URL, ERROR_MESSAGE } from "src/constants";
import { createUser, AuthContext } from "src/services/authService";
import { createUserData } from "src/services/dataService";
import { createAccountValiadtion } from "src/validationSchemas";
import { SubmitButton } from "src/components/Button";
import { Notification, NotificationState } from "src/components/Notification";
import { PageLayout } from "src/components/PageLayout";
import { LoadingPage } from "src/components/Loading";

export function CreateAccount() {
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState("");
  const [udatingUserData, setUdatingUserData] = useState(false);
  const pageHeading = "Create Account";

  if (udatingUserData) {
    return (
      <PageLayout heading={pageHeading}>
        <LoadingPage />
      </PageLayout>
    );
  }

  return (
    <PageLayout
      heading={pageHeading}
      className={c("w-4/5", "max-w-md", "py-12")}
    >
      {user ? (
        <SignOut />
      ) : (
        <>
          <Formik
            initialValues={{
              email: "",
              password: "",
              username: "",
            }}
            validationSchema={createAccountValiadtion}
            onSubmit={async ({ email, password, username }) => {
              setErrorMessage("");
              try {
                const { user } = await createUser(email, password);
                setUdatingUserData(true);
                if (user) {
                  try {
                    await Promise.all([
                      user.updateProfile({ displayName: username }),
                      createUserData(user.uid, {
                        isQuizMaster: true,
                        displayName: username,
                      }),
                    ]);
                    setUdatingUserData(false);
                  } catch {
                    history.push(HOME_URL);
                  }
                  history.push(HOME_URL);
                } else {
                  throw new Error(ERROR_MESSAGE.DEFAULT);
                }
              } catch (error) {
                setUdatingUserData(false);
                if (error?.message) {
                  setErrorMessage(error.message);
                } else {
                  setErrorMessage(ERROR_MESSAGE.DEFAULT);
                }
              }
            }}
          >
            {() => (
              <Form>
                <Input
                  name="username"
                  label="Username"
                  id="login-username"
                  autoComplete="on"
                />
                <Input
                  name="email"
                  label="Email"
                  id="login-email"
                  autoComplete="on"
                  className="mt-4"
                />
                <Input
                  name="password"
                  label="Password"
                  id="login-password"
                  className="mt-4"
                  type="password"
                />
                {errorMessage ? (
                  <Notification
                    className="mt-8"
                    state={NotificationState.ERROR}
                  >
                    {errorMessage}
                  </Notification>
                ) : null}
                <SubmitButton className="mt-8">Create account</SubmitButton>
              </Form>
            )}
          </Formik>
          <section className="mt-10 w-full flex flex-col items-center">
            <p>Already got an account?</p>
            <Link to={LOGIN_URL} className="qb-a mt-4">
              Login instead
            </Link>
          </section>
        </>
      )}
    </PageLayout>
  );
}
