import React, { useState, useContext, useEffect } from "react";
import c from "classnames";

import {
  Quiz,
  Player,
  PLAYER_ADMISSION_STATUS,
  QUIZ_STATUS,
  subscribeQuizPlayers,
} from "src/services/dataService";
import { AuthContext } from "src/services/authService";
import { UserDisplayName } from "src/components/UserDisplayName";
import { Loading } from "src/components/Loading";

import { JoinQuizForm } from "./JoinQuizForm";
import { QuizMasterActions } from "./QuizMasterActions";
import { PlayerActions } from "./PlayerActions";
import { QuizStatus } from "./QuizStatus";

interface QuizActionsProps {
  quiz: Quiz;
  players: Player[];
}

function QuizActions({ quiz, players }: QuizActionsProps) {
  const { user, isQuizMaster } = useContext(AuthContext);
  const userPlayer = players.find(
    (player) => user?.uid && player.id === user.uid
  );

  if (isQuizMaster && user?.uid === quiz.createdBy) {
    return <QuizMasterActions quiz={quiz} players={players} />;
  }

  if (userPlayer) {
    return <PlayerActions quiz={quiz} player={userPlayer} />;
  }

  if (user && quiz.status !== QUIZ_STATUS.COMPLETED) {
    return (
      <div className={c("flex", "justify-center", "px-4", "pb-8")}>
        <div className={c("w-full", "max-w-sm")}>
          <JoinQuizForm quiz={quiz} user={user} />
        </div>
      </div>
    );
  }

  return null;
}

interface QuizSummaryProps {
  quiz: Quiz;
  players: Player[];
}

function QuizSummary({ quiz, players }: QuizSummaryProps) {
  const { title, rounds, createdBy } = quiz;

  const approvedPlayers = players.filter(
    (player) => player.admissionStatus === PLAYER_ADMISSION_STATUS.APPROVED
  ).length;
  const pendingPlayers = players.filter(
    (player) => player.admissionStatus === PLAYER_ADMISSION_STATUS.PENDING
  ).length;
  const rejectedPlayers = players.filter(
    (player) => player.admissionStatus === PLAYER_ADMISSION_STATUS.REJECTED
  ).length;

  return (
    <>
      <div
        className={c(
          "flex",
          "flex-col",
          "sm:flex-row",
          "w-full",
          "justify-between",
          "items-center",
          "mt-4",
          "px-8"
        )}
      >
        <h3 className={c("text-primary", "sm:text-left", "text-4xl")}>
          {title}
        </h3>
        <div className={c("sm:text-right")}>
          <QuizStatus quiz={quiz} />
        </div>
      </div>
      <div className={c("w-full", "pl-8", "text-xl", "sm:text-2xl")}>
        <ul className={c("p-4", "text-left", "list-disc")}>
          <li>
            Quiz Master:{" "}
            <span className={c("font-semibold")}>
              <UserDisplayName uid={createdBy} />
            </span>
          </li>
          <li>
            Total Rounds:{" "}
            <span className={c("font-semibold")}>{rounds.length}</span>
          </li>
          <li>
            Total Questions:{" "}
            <span className={c("font-semibold")}>
              {rounds.reduce((acc, round) => round.questions.length + acc, 0)}
            </span>
          </li>
          <li>
            <span>
              Confirmed Teams:{" "}
              <span className={c("font-semibold")}>{approvedPlayers}</span>
            </span>{" "}
            <br className={c("sm:hidden")} />
            <span
              className={c("text-lg", "sm:text-xl", "text-gray-400", "sm:ml-2")}
            >
              ({pendingPlayers} pending {"&"} {rejectedPlayers} rejected)
            </span>
          </li>
        </ul>
      </div>
      <div className={c("text-xl", "sm:text-2xl")}>
        <QuizActions quiz={quiz} players={players} />
      </div>
    </>
  );
}

interface QuizSummaryCardProps {
  quiz: Quiz;
}

export function QuizSummaryCard({ quiz }: QuizSummaryCardProps) {
  const { user } = useContext(AuthContext);
  const [quizPlayers, setQuizPlayers] = useState<Player[]>([]);
  const [loading, setLoading] = useState(true);

  const userPlayer = quizPlayers.find(
    (player) => user?.uid && player.id === user.uid
  );

  const isHost = user?.uid === quiz.createdBy;
  const isApproved =
    userPlayer?.admissionStatus === PLAYER_ADMISSION_STATUS.APPROVED;
  const isRejected =
    userPlayer?.admissionStatus === PLAYER_ADMISSION_STATUS.REJECTED;
  const isPending =
    userPlayer?.admissionStatus === PLAYER_ADMISSION_STATUS.PENDING;

  useEffect(() => {
    const unsubscribe = subscribeQuizPlayers(
      quiz.id,
      (players) => {
        setQuizPlayers(players);
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );

    return function () {
      unsubscribe();
    };
  }, [quiz.id]);

  return (
    <div
      className={c(
        "border-2",
        "border-solid",
        "border-white",
        "bg-secondary-dark",
        "rounded",
        "my-12",
        "w-full",
        "shadow-white",
        {
          "shadow-success border-success border-2": isApproved,
          "shadow-error border-error": isRejected,
          "shadow-primary border-primary": isPending || isHost,
        }
      )}
    >
      {loading ? (
        <div className="py-32">
          <Loading size={30} />
        </div>
      ) : (
        <QuizSummary quiz={quiz} players={quizPlayers} />
      )}
    </div>
  );
}
