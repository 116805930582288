import React, { useContext, HTMLAttributes } from "react";

import { QuizContext, QUIZ_STATUS } from "src/services/dataService";

export function WinningTeam(props: HTMLAttributes<HTMLSpanElement>) {
  const { quiz, approvedPlayers } = useContext(QuizContext);

  if (quiz && quiz.status === QUIZ_STATUS.COMPLETED) {
    const playersWithScores = approvedPlayers.map(({ teamName, rounds }) => ({
      teamName,
      totalScore: rounds
        .filter((r) => r.isMarked)
        .reduce((accRounds, curRound) => {
          return (
            accRounds +
            curRound.answers.reduce((acc, cur) => acc + (cur.score || 0), 0)
          );
        }, 0),
    }));
    playersWithScores.sort((a, b) => b.totalScore - a.totalScore);

    return <span {...props}>{playersWithScores[0]?.teamName || ""}</span>;
  }

  return null;
}
