import React, { useContext } from "react";
import { Switch, Route, Redirect, RouteProps } from "react-router-dom";

import {
  LOGIN_URL,
  HOME_URL,
  CREATE_ACCOUNT_URL,
  EDIT_QUIZ_URL,
  CREATE_QUIZ_URL,
  PLAYER_QUIZ_URL,
  HOST_QUIZ_URL,
  MY_QUIZZES_URL,
} from "src/constants";
import { AuthContext } from "src/services/authService";
import { CreateAccount } from "src/pages/CreateAccount";
import { EditQuiz } from "src/pages/EditQuiz";
import { HomePage } from "src/pages/Home";
import { Login } from "src/pages/Login";
import { PlayerQuizPage } from "src/pages/PlayerQuiz";
import { HostQuizPage } from "src/pages/HostQuiz";
import { MyQuizzes } from "./pages/MyQuizzes";

export function ProtectedRoute(props: RouteProps) {
  const { user } = useContext(AuthContext);

  if (user) {
    return <Route {...props} />;
  }
  return <Redirect to={HOME_URL} />;
}

export function QuizMasterProtectedRoute(props: RouteProps) {
  const { isQuizMaster } = useContext(AuthContext);

  if (isQuizMaster) {
    return <Route {...props} />;
  }
  return <Redirect to={HOME_URL} />;
}

export function Routes() {
  return (
    <Switch>
      <Route exact path={HOME_URL}>
        <HomePage />
      </Route>
      <Route path={LOGIN_URL}>
        <Login />
      </Route>
      <Route path={CREATE_ACCOUNT_URL}>
        <CreateAccount />
      </Route>

      <ProtectedRoute path={`${PLAYER_QUIZ_URL}/:quizId`}>
        <PlayerQuizPage />
      </ProtectedRoute>

      <QuizMasterProtectedRoute path={`${MY_QUIZZES_URL}`}>
        <MyQuizzes />
      </QuizMasterProtectedRoute>
      <QuizMasterProtectedRoute path={`${EDIT_QUIZ_URL}/:quizId`}>
        <EditQuiz isCreateQuiz={false} />
      </QuizMasterProtectedRoute>
      <QuizMasterProtectedRoute path={`${CREATE_QUIZ_URL}/:quizId`}>
        <EditQuiz isCreateQuiz={true} />
      </QuizMasterProtectedRoute>
      <QuizMasterProtectedRoute path={`${HOST_QUIZ_URL}/:quizId`}>
        <HostQuizPage />
      </QuizMasterProtectedRoute>

      <Route>
        <Redirect to={HOME_URL} />
      </Route>
    </Switch>
  );
}
