import React from "react";
import { Link } from "react-router-dom";
import c from "classnames";

import { PLAYER_QUIZ_URL } from "src/constants";
import {
  Quiz,
  Player,
  PLAYER_ADMISSION_STATUS,
  QUIZ_STATUS,
} from "src/services/dataService";

interface PlayerActionsProps {
  quiz: Quiz;
  player: Player;
}

export function PlayerActions({ quiz, player }: PlayerActionsProps) {
  if (player.admissionStatus === PLAYER_ADMISSION_STATUS.REJECTED) {
    return (
      <p className={c("px-10", "sm:px-20", "pb-8", "mt-2", "text-error")}>
        Sorry, you've been denied access to this quiz.
      </p>
    );
  }

  if (player.admissionStatus === PLAYER_ADMISSION_STATUS.PENDING) {
    return (
      <p className={c("px-10", "sm:px-20", "pb-8", "mt-2", "text-primary")}>
        Your request to join is awaiting approval.
      </p>
    );
  }

  if (player.admissionStatus === PLAYER_ADMISSION_STATUS.APPROVED) {
    const isQuizStarted = quiz.status === QUIZ_STATUS.STARTED;
    return (
      <>
        <p className={c("px-10", "sm:px-20", "pb-8", "mt-2", "text-success")}>
          Your request to join has been approved.
          {isQuizStarted ? null : (
            <>
              <br />
              Waiting for quiz to start.
            </>
          )}
        </p>
        {isQuizStarted ? (
          <div className={c("px-8", "pb-8", "flex", "justify-center")}>
            <Link to={`${PLAYER_QUIZ_URL}/${quiz.id}`} className={c("qb-a", "w-full", "max-w-lg")}>
              Go To Quiz
            </Link>
          </div>
        ) : null}
      </>
    );
  }

  return null;
}
