import React from "react";
import c from "classnames";

interface HorizontalBreakProps {
  noMargin?: boolean
  className?: string
}

export function HorizontalBreak({ noMargin = false, className }: HorizontalBreakProps) {
  return (
    <div className={c("w-full", "flex", "justify-center")}>
      <hr
        className={c(
          "border-t",
          "border-dashed",
          "border-white",
          {
            "mt-16 mb-12": !noMargin,
            "m-0": noMargin,
          },
          className
        )}
      />
    </div>
  );
}
