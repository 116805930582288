import React, { Component, PropsWithChildren } from "react";

interface ErrorBoundaryProps extends PropsWithChildren<{}> {}

interface ErrorBoundaryState {
  hasError: boolean;
}

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <h1 style={{ fontSize: "3rem" }}>
          Looks like something went wrong, sorry
          <br />
          <div style={{ marginTop: "1rem" }}>
            <span role="img" aria-label="Grimacing Face Emoji">
              😬
            </span>
          </div>
        </h1>
      );
    }

    return this.props.children;
  }
}
