import * as firebase from "firebase/app";
import dayjs from "dayjs";

type DocumentData = firebase.firestore.DocumentData;
type DocumentSnapshot = firebase.firestore.DocumentSnapshot<DocumentData>;

export enum QUIZ_STATUS {
  COMPLETED = "completed",
  DRAFT = "draft",
  SCHEDULED = "scheduled",
  STARTED = "started",
}

export interface Question {
  questionNumber: number;
  text: string;
  answer: string;
  imageIncluded?: boolean;
  imageUrl?: string;
}

export interface Round {
  title: string;
  description?: string;
  questions: Question[];
  roundNumber: number;
}

export interface Quiz {
  id: string;
  title: string;
  scheduledStartUnix: number; // Scheduled start in unix epoch seconds
  createdBy: string;
  rounds: Round[];
  status: QUIZ_STATUS;
}

function convertSnapshotToQuiz(snapshot: DocumentSnapshot): Quiz {
  const rounds = [...(snapshot.data()?.rounds ?? [])].map((round: Round) => ({
    ...round,
    questions: round.questions.sort(
      (a, b) => a.questionNumber - b.questionNumber
    ),
  }));

  return {
    id: snapshot.id,
    ...snapshot.data(),
    rounds: rounds.sort((a, b) => a.roundNumber - b.roundNumber),
  } as Quiz;
}

export function createQuiz(createdBy: string) {
  return firebase
    .firestore()
    .collection("quizzes")
    .add({
      createdBy,
      scheduledStartUnix: dayjs().add(1, "day").hour(19).minute(0).unix(),
      status: QUIZ_STATUS.DRAFT,
      title: "",
      rounds: [],
    });
}

export function duplicateQuiz(quiz: Quiz, createdBy: string) {
  return firebase
    .firestore()
    .collection("quizzes")
    .add({
      rounds: quiz.rounds,
      createdBy,
      scheduledStartUnix: dayjs().add(1, "day").hour(19).minute(0).unix(),
      status: QUIZ_STATUS.SCHEDULED,
      title: `${quiz.title} (Copy)`,
    });
}

export function updateQuiz(
  quizId: string,
  quizData: firebase.firestore.UpdateData
) {
  return firebase
    .firestore()
    .collection("quizzes")
    .doc(quizId)
    .update(quizData);
}

export function deleteQuiz(quizId: string) {
  return firebase.firestore().collection("quizzes").doc(quizId).delete();
}

export function startQuiz(quizId: string) {
  return updateQuiz(quizId, { status: QUIZ_STATUS.STARTED });
}

export function subscribeQuizData(
  quizId: string,
  onNext: (quiz: Quiz | null) => void,
  onError?: (error: Error) => void
) {
  return firebase
    .firestore()
    .collection("quizzes")
    .doc(quizId)
    .onSnapshot((snapshot) => {
      if (snapshot.exists) {
        onNext(convertSnapshotToQuiz(snapshot));
      } else {
        onNext(null);
      }
    }, onError);
}

export function subscribeQuizzesByStatus(
  statuses: QUIZ_STATUS[],
  onNext: (quizzes: Quiz[]) => void,
  onError?: (error: Error) => void
) {
  return firebase
    .firestore()
    .collection("quizzes")
    .where("status", "in", statuses)
    .orderBy("scheduledStartUnix", "asc")
    .onSnapshot((snapshot) => {
      const quizzes: Quiz[] = [];
      snapshot.forEach((doc) => {
        quizzes.push(convertSnapshotToQuiz(doc));
      });
      onNext(quizzes);
    }, onError);
}

export function subscribeUserQuizzesByStatus(
  uid: string,
  statuses: QUIZ_STATUS[],
  onNext: (quizzes: Quiz[]) => void,
  onError?: (error: Error) => void
) {
  return firebase
    .firestore()
    .collection("quizzes")
    .where("createdBy", "==", uid)
    .where("status", "in", statuses)
    .orderBy("scheduledStartUnix", "asc")
    .onSnapshot((snapshot) => {
      const quizzes: Quiz[] = [];
      snapshot.forEach((doc) => {
        quizzes.push(convertSnapshotToQuiz(doc));
      });
      onNext(quizzes);
    }, onError);
}
